import React, { Component } from 'react';
import { commonService } from "../../../storeManager/common/services";
import GlobalConfig from "../../../globalConfig/globalConfig";
import Loader from "../../Loader";
import { MESSAGES, PATHS } from "../../../constants";
import Error from "../../Common/Error";
import TableComponent from "../../Common/TableComponent";
import { MODALS } from "../../../storeManager/modal/constants";
import { bindActionCreators } from "redux";
import { openModal as openModalHandler } from "../../../storeManager/modal/actions";
import { connect } from "react-redux";
import Modal from "../../Common/Modal";
import { TABLE_HEADER } from './../../../constants/index';
class ActivityDetail extends Component {
  state = {
    isLoading: true,
    activityModalData: {},
    error: '',
    transactionIdSequence: "",
    csvLink: '',
  };

  componentDidMount() {
    const {
      history: {
        location: {
          state: { transactionId, activityType, transactionIdSequence }
        }
      }, history
    } = this.props;
    if (transactionId && activityType) {
      this.setState({ transactionIdSequence });
      this.getActivitiesDetails(transactionId, activityType)
        .catch(() => {
          console.error(MESSAGES.DID_MOUNT_ERROR);
        });
    } else history.push(PATHS.ACTIVITIES)
  }
  generateCSV = (array) => {
    const newArray = [];
    const headerArr = []
    headerArr.push(TABLE_HEADER.subscriberId)
    headerArr.push(TABLE_HEADER.transactionId)
    headerArr.push(TABLE_HEADER.fromdate)
    headerArr.push(TABLE_HEADER.status)
    if (array.some(x => x.amount))
      headerArr.push(TABLE_HEADER.amount)
    headerArr.push(TABLE_HEADER.packName)
    // if (array.some(x => x.amount))
    headerArr.push(TABLE_HEADER.message)
    array.forEach(item => {
      const itemObj = {}
      itemObj.subscriberId = item.subscriberId || '-';
      itemObj.transactionId = item.masterTransactionId || '-';
      itemObj.fromdate = item.fromDate || '-';
      itemObj.status = item.status || '-';
      if (item.amount)
        itemObj.amount = item.amount || '-';
      itemObj.packName = item.packName || '-';
      itemObj.message = item.message ? `"${item.message}"` : "N/A";
      newArray.push(itemObj)
    })
    const csvString = [
      headerArr,
      ...newArray.map(item => [
        ...Object.keys(newArray[0])
          .map(subItem => item[subItem])
      ]
      )
    ]
      .map(e => e.join(","))
      .join("\n");
    let csvContent = "data:text/csv;charset=utf-8," + csvString;

    this.setState({ csvLink: encodeURI(csvContent) });
  }
  getActivitiesDetails = async (transactionId, activityType) => {
    const payload = {
      transactionId,
      activityType
    };
    try {
      const response = await commonService.activitiesDetailsApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        let activityDetailData = responseData.data;
        this.generateCSV(activityDetailData)
        const filterData = activityDetailData.map(columnData => {
          if (columnData.amount) {
            return {
              subscriberId: columnData.subscriberId,
              transactionId: columnData.masterTransactionId,
              fromdate: columnData.fromDate || '-',
              status: columnData.status || '-',
              amount: columnData.amount ? `₹ ${columnData.amount}` : '-',
              packName: columnData.packName || '-',
              message: columnData.message || 'N/A'
            }
          } else {
            return {
              subscriberId: columnData.subscriberId,
              transactionId: columnData.masterTransactionId,
              fromdate: columnData.fromDate || '-',
              status: columnData.status || '-',
              packName: columnData.packName || '-',
              message: columnData.message || 'N/A'
            }
          }

        });
        this.setState({ activityModalData: filterData, isLoading: false });
      } else {
        this.setState({ error: responseData.message, isLoading: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false })
    }
  };


  openConfirmationModal = async (id, activityType) => {
    this.setState({ isLoading: true });
    const { openModal } = this.props;
    const payload = {
      transactionId: id,
      activityType
    };
    try {
      const response = await commonService.activitiesDetailConfirmationApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        this.setState({ isLoading: false });
        openModal(MODALS.CONFIRMATION_POPUP, { message: responseData.message, reDirectLinkHide: true });
      } else {
        this.setState({ error: responseData.message, isLoading: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false })
    }

  };

  handleBackBtn = () => {
    const { history } = this.props;
    history.push(PATHS.ACTIVITIES);
  };

  render() {
    const { activityModalData, isLoading, error, transactionIdSequence, csvLink } = this.state;
    return (
      <div className="content-wrapper activity-detail-wrapper pb60">
        {
          isLoading && <Loader />
        }
        <div className="prev-page-link pb20" onClick={() => this.handleBackBtn()}>
          <i className="back-link">
            <svg width="25" height="16">
              <path fill="currentColor" fillRule="evenodd"
                d="M8.336.599a.923.923 0 0 1 1.211 0 .703.703 0 0 1 0 1.067L3.406 7.131h20.265c.473 0 .86.334.86.755 0 .42-.387.765-.86.765H3.406l6.141 5.456a.714.714 0 0 1 0 1.077.923.923 0 0 1-1.211 0L.741 8.425a.703.703 0 0 1 0-1.067L8.336.599z" />
            </svg>
          </i>
          Back
        </div>
        <div className="b2b-modal-header pb20">
          <div className="gradient-text">
            Primary Transaction ID: {' '}
            <span className="value pb10">
              {transactionIdSequence ? transactionIdSequence : 'NA'}
            </span>
          </div>
        </div>
        {csvLink && <a className='reset-link download' href={csvLink} download={transactionIdSequence ? `${transactionIdSequence}.csv` : 'Transaction.csv'}>Export to CSV</a>}
        {
          !error && activityModalData.length ? (
            <TableComponent
              tableData={activityModalData}
              tableClass={"subscriber-details-table acti-tab"}

            />
          ) : <Error error={error} />
        }
        <Modal />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: bindActionCreators(openModalHandler, dispatch),
  }
};

export default connect(null, mapDispatchToProps)(ActivityDetail);