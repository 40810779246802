import React from 'react';
import './content-loader.scss';

const ContentLoader = (props) => {
    const {rows, cellData, area } = props;

    const numberOfRows = [];
	for (let i = 0; i < rows; i++) {
		numberOfRows.push(<div className={"animated-background"} id={area} key={i} />);
	}

    return (
      <div className={`${cellData ? "content-loader cell-data" : "content-loader"}`} id={area}>
        {numberOfRows}
      </div>
    )
};

export default ContentLoader;