import React, { useState, useEffect } from "react";
import './get-new-connection.scss';

import { bindActionCreators } from "redux";
import { connect } from 'react-redux';

import { commonService } from "../../storeManager/common/services";
import GlobalConfig from "../../globalConfig/globalConfig";
import { MODALS } from "../../storeManager/modal/constants";
import Modal from "../Common/Modal";
import { openModal as openModalHandler } from "../../storeManager/modal/actions";


const GetNewConnection = (props) => {
  const initialFormState = {
    connectionFor: "",
    existingChainID: localStorage.getItem("chKeyId") ? `${atob(localStorage.getItem("chKeyId"))}` : "",
    existingSubID: "",
    companyProperty: "",
    contactPerson: "",
    mobileNumber: "",
    emailID: "",
    pinCode: "",
    city: "",
    state: "",
    noOfConnections: "",
    upgradeRequired: "",
  };

  const initialFormErrorsState = {
    connectionFor: null,
    existingChainID: null,
    existingSubID: null,
    companyProperty: null,
    contactPerson: null,
    mobileNumber: null,
    emailID: null,
    pinCode: null,
    city: null,
    state: null,
    noOfConnections: null,
    upgradeRequired: null,
  };

  const [form, setForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState(initialFormErrorsState);
  const [dropdownData, setDropdownData] = useState([]);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");

  useEffect(() => {
    getDropDownData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateField = (name, value) => {
    let errorMsg = null;

    // Field-specific validation logic
    switch (name) {
      case "connectionFor":
      case "companyProperty":
      case "contactPerson":
        if (!value) errorMsg = "This field is required.";
        break;
      case "existingChainID":
        if (!value) {
          errorMsg = "This field is required.";
        } else if (!/^\d{10}$/.test(value)) {
          errorMsg = "Existing Chain ID must be a 10-digit number.";
        } else {
          // You can add a check to see if the ID exists in the database.
        }
        break;
        case "existingSubID":
          if (value && (!/^\d{10}$/.test(value))) {
          errorMsg = "Existing sub ID must be a 10-digit number.";
        } else {
          // You can add a check to see if the ID exists in the database.
        }
        break;
      case "mobileNumber":
        if (!value) {
          errorMsg = "This field is required.";
        } else if (!/^[6-9]\d{9}$/.test(value)) {
          errorMsg = "Mobile Number must start with 6, 7, 8, or 9 and be 10 digits long.";
        }
        break;
      case "emailID":
        if (!value) {
          errorMsg = "This field is required.";
        } else if (
          !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
        ) {
          errorMsg = "Please enter a valid Email ID.";
        }
        break;
      case "pinCode":
        if (!value) {
          errorMsg = "This field is required.";
        }
        else if (value.length < 6) {
          errorMsg = "Pin Code must be at least 6 digits long.";

        }
         else  {
          
        }
        break;
      case "noOfConnections":
        if (!value) {
          errorMsg = "This field is required.";
        } else if (!/^\d{1,10}$/.test(value)) {
          errorMsg = "Please enter a valid number (up to 10 digits).";
        }
        break;
      default:
        break;
    }

    return errorMsg;
  };


  const openPopup = (responseData) => {
    const {openModal} = props;
   openModal(MODALS.CONFIRMATION_POPUP, { message: responseData.message, hideCloseButton: true, })
  };

  const handlePinCodeChange = (e) => {
    const { name, value } = e.target;
    
    setForm({ ...form, [name]: value });
    if (value.length === 6) {
      updatePincodeData(value);
    }
   else{
    setPinCodeError("");
    setForm({ ...form, city: "", state: "" });
   }
      
    
  };

  const getDropDownData = async () => {

    try {
      const response = await commonService.dropDownList();
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        setDropdownData(responseData.data);
      } else {

      }
    } catch {


    }
  }

  // getSuspendDetail = async (subId, noOfRows) => {
  //   const { openModal } = this.props;
  //   const payload = {
  //     "subscriberId": subId,
  //     noOfRows
  //   };
  //   try {
  //     const response = await commonService.suspendStatusApi(payload);


  const saveForm = async (payload) => {

    try {
      const response = await commonService.saveForm(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        openPopup(responseData);
      } else {
        openPopup(responseData);
      }
    } catch (error) {

      console.error("Error fetching dropdown data:", error);
    }
  }

  const updatePincodeData = async (pincode) => {
    try {
      const response = await commonService.updatePincode(pincode);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        const { city, state } = responseData.data;
        setForm({
          ...form,
          city,
          state,
        });

      } else {
        // errorMsg = "This field is required.";
        setPinCodeError("Pin code is invalid.");
      }
    } catch {


    }
  }


  const handleInputAlphabets = (e) => {
    const inputValue = e.target.value;
    const alphabeticValue = inputValue.replace(/[^A-Za-z]/g, '');
    e.target.value = alphabeticValue;
    handleChange(e);
  }

  const handleInput = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    e.target.value = numericValue;
    handleChange(e);
  }



  const handleSubmit = () => {

    const errorObj = {};

    // Validate each field
    Object.keys(formErrors).forEach((name) => {
      const msg = validateField(name, form[name]);
      if (msg) errorObj[name] = msg;
    });
    if (form.pinCode === '') {

      errorObj.pinCode = 'This field is required.';
      errorObj.city = 'This field is required.';
      errorObj.state = 'This field is required.';
    }
    if (formErrors.pinCode) {
      errorObj.city = formErrors.pinCode;
      errorObj.state = formErrors.pinCode;
    }
  
    if (Object.keys(errorObj).length !== 0) {
      setFormErrors({ ...formErrors, ...errorObj });
    }
   
     else {
      if (!pinCodeError && Object.keys(errorObj).length === 0) {
      const payload = {
        chainId: form.existingChainID,
        city: form.city,
        connectionFor: form.connectionFor,
        connectionRequired: form.noOfConnections,
        contactNumber: form.mobileNumber,
        email: form.emailID,
        contactPerson: form.contactPerson,
        state: form.state,
        subId: form.existingSubID,
        pincode: form.pinCode,
        upgradeRequired: form.upgradeRequired,
        propertyName: form.companyProperty
      };
      saveForm(payload);
    }}
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormErrors(initialFormErrorsState);

    if (name === "connectionFor") {
      setSelectedDropdownOption(value);
      // Clear the error for the selected field.
      setFormErrors({ ...formErrors, [name]: null });
    }
    if(name === "pinCode"){
      setPinCodeError(""); 
    }
   
    const formObj = {
      ...form,
      [name]: value,
    };

    setForm(formObj, () => {
      const formErrorsObj = { ...formErrors };
      const errorMsg = validateField(name, value);
      formErrorsObj[name] = errorMsg;
      setFormErrors(formErrorsObj);
    });

  };
  
  return (
    <div className="signup-box">
      <h1 className="title">Sign up</h1>

      <div className="js-form-item">
        <label htmlFor="connectionFor" className="label-left">
          Connection for*:
        </label>
        <select
          className="input-element"
          name="connectionFor"
          id="connectionFor"
          //  onClick={getDropDownData}
          value={selectedDropdownOption}
          onChange={handleChange}
        >
          <option disabled={true} value="">
            --Choose and option--
          </option>
          {dropdownData.map((item) => (
            <option value={item.id}>{item}</option>
          ))}

        </select>
        {formErrors.connectionFor && !selectedDropdownOption && (
          <span className="err">{formErrors.connectionFor}</span>
        )}
      </div>

      <div className="js-form-item">
        <label htmlFor="existingChainID" className="label-left">
          Existing Chain ID*:
        </label>
        <input
          className="input-element"
          type="text"
          name="existingChainID"
          id="existingChainID"
          value={form.existingChainID}
          onChange={handleChange}
          // onBlur={handleChange}
          onInput={handleInput}
          maxLength={10}
          disabled={true} // Auto-populated field
        />
        {formErrors.existingChainID && (
          <span className="err">{formErrors.existingChainID}</span>
        )}
      </div>

      <div className="js-form-item">
        <label htmlFor="existingSubID" className="label-left">
          Any one Existing Sub ID:
        </label>
        <input
          className="input-element"
          type="text"
          name="existingSubID"
          id="existingSubID"
          value={form.existingSubID}
          onChange={handleChange}
          // onBlur={handleChange}
          onInput={handleInput}
          maxLength={10}

        />
        {formErrors.existingSubID && (
          <span className="err">{formErrors.existingSubID}</span>
        )}
        
      </div>

      <div className="js-form-item">
        <label htmlFor="companyProperty" className="label-left">
          Company/Property Name*:
        </label>
        <input
          className="input-element"
          type="text"
          name="companyProperty"
          id="companyProperty"
          value={form.companyProperty}
          onChange={handleChange}
          // onInput={handleInputAlphabets}
          // onBlur={handleChange}
          maxLength={200}

        />
        {formErrors.companyProperty && (
          <span className="err">{formErrors.companyProperty}</span>
        )}
      </div>

      <div className="js-form-item">
        <label htmlFor="contactPerson" className="label-left">
          Contact Person*:
        </label>
        <input
          className="input-element"
          type="text"
          name="contactPerson"
          id="contactPerson"
          value={form.contactPerson}
          onChange={handleChange}
          onInput={handleInputAlphabets}
          //  onBlur={handleChange}
          maxLength={100}

        />
        {formErrors.contactPerson && (
          <span className="err">{formErrors.contactPerson}</span>
        )}
      </div>

      <div className="js-form-item">
        <label htmlFor="mobileNumber" className="label-left">
          Mobile Number*:
        </label>
        <input
          className="input-element"
          type="text"
          name="mobileNumber"
          id="mobileNumber"
          value={form.mobileNumber}
          onChange={handleChange}
          onInput={handleInput}
          // onBlur={handleChange}
          maxLength={10}

        />
        {formErrors.mobileNumber && (
          <span className="err">{formErrors.mobileNumber}</span>
        )}
      </div>

      <div className="js-form-item">
        <label htmlFor="emailID" className="label-left">
          Email ID*:
        </label>
        <input
          className="input-element"
          type="text"
          name="emailID"
          id="emailID"
          value={form.emailID}
          onChange={handleChange}
        // onBlur={handleChange}
        />
        {formErrors.emailID && (
          <span className="err">{formErrors.emailID}</span>
        )}
      </div>

      <div className="js-form-item">
        <label htmlFor="pinCode" className="label-left">
          Pin Code*:
        </label>
        <input
          className="input-element"
          type="text"
          name="pinCode"
          id="pinCode"
          value={form.pinCode}
          onChange={handleChange}
          onInput={handleInput}
          onBlur={(e) => handlePinCodeChange(e)}
          maxLength={6}
        />
        {/* {formErrors.pinCode && (
          <span className="err">{formErrors.pinCode}</span>
        )} */}
        {formErrors.pinCode && (
            <span className="err">{formErrors.pinCode}</span>
          )}
          {pinCodeError && (
            <span className="err">{pinCodeError}</span>
          )}
         
        

      </div>

      <div className="js-form-item">
        <label htmlFor="city" className="label-left">
          City*:
        </label>
        <input
          className="input-element"
          type="text"
          name="city"
          id="city"
          value={form.city}
          onChange={handleChange}
          onBlur={handleChange}
          disabled={true} // Auto-populated field
        />
        {formErrors.city && <span className="err">{formErrors.city}</span>}
        {formErrors.pinCode && (
            <span className="err">City is required.</span>
          )}
      </div>

      <div className="js-form-item">
        <label htmlFor="state" className="label-left">
          State*:
        </label>
        <input
          className="input-element"
          type="text"
          name="state"
          id="state"
          value={form.state}
          onChange={handleChange}
          onBlur={handleChange}
          disabled={true} // Auto-populated field
        />
        {formErrors.state && <span className="err">{formErrors.state}</span>}
        {formErrors.pinCode && (
            <span className="err">State is required.</span>
          )}
      </div>

      <div className="js-form-item">
        <label htmlFor="noOfConnections" className="label-left">
          No of Connections Required*:
        </label>
        <input
          className="input-element"
          type="text"
          name="noOfConnections"
          id="noOfConnections"
          value={form.noOfConnections}
          onChange={handleChange}
          maxLength={10}
          onInput={handleInput}
        // onBlur={handleChange}
        />
        {formErrors.noOfConnections && (
          <span className="err">{formErrors.noOfConnections}</span>
        )}
      </div>

      <div className="js-form-item">
        <label htmlFor="upgradeRequired" className="label-left">
          Upgrade Required:
        </label>
        <input
          className="input-element"
          type="text"
          name="upgradeRequired"
          id="upgradeRequired"
          value={form.upgradeRequired}
          onChange={handleChange}
          maxLength={100}
        // onBlur={handleChange}
        />
      </div>

      <div className="js-form-item" style={{ alignItems: "center" }}>
        <input
          type="button"
          className="btn btn-primary"
          value="Submit"
          onClick={handleSubmit}
        />

      </div>

      <Modal />

    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: bindActionCreators(openModalHandler, dispatch),
  }
};

export default connect(null, mapDispatchToProps)(GetNewConnection);

