import { isEmpty } from 'lodash';
import React, { useState } from 'react'
// import packsDummy from '../../../mocks/pack.json'
import CustomButton from '../../Common/Button';
import PackCard from '../../Common/PackCard';
// import Loader from '../../Loader';
import ReactLoading from 'react-loading';

const TpCorporatePacks = (props) => {
    const { data, onChange, selectedPack, handleNext, isDashboard, handleProceed } = props;
    const [maxCount, setMaxCount] = useState(12);
    const [isLoading, setIsLoading] = useState(false);
    
    const handleBtn = () => {
        setIsLoading(true)
        setMaxCount(maxCount + 8)
        setTimeout(() => {
            setIsLoading(false)
            window.scrollTo({ top: document.body.scrollHeight - 800, behavior: 'smooth' })
        }, 400)
    }
    const mapData = () => {
        const finalData = [];
        for (let i = 0; i < maxCount && i < data.length; i++) {
            finalData.push(
                <PackCard
                    cardData={data[i]}
                    key={`${i}-${data[i].title}`}
                    onChange={onChange}
                    name="tp_corporate_pack"
                    type="radio"
                    value={JSON.stringify({ name: data[i].pack_name, amount: data[i].pack_mrp })}
                    id={`id-${i}`}
                    selectedPack={selectedPack}
                />
            )
        }
        return finalData;
    }
    return (
        <div className='tp-packs'>
            {/*isLoading && <Loader />*/}
            <div className="four-column packs">
                {
                    mapData()
                }

            </div>
            <div className='bulkOperationFooter'>
                <div className='footerWrapper'>
                    {maxCount < data.length &&<div className='bulkOperationFooter-loadmore-sec'>
                        {/* Showing <b>{maxCount > data.length ? data.length : maxCount}</b> / <b>{data.length}</b> */}
                    <CustomButton
                        onClick={handleBtn}
                        // content="Load More"
                        classes="primary"
                        config={{
                            type: 'button',
                            class: 'secondary'
                        }}
                    >
                        {isLoading ? <ReactLoading type={'bars'} color={'#E10092'} height={22} width={25} /> : <span>Load More Packs ({maxCount > data.length ? data.length : maxCount} / {data.length})</span>}
                    </CustomButton>
                    </div>}
                    <div className='bulkOperationFooter-button-sec'>
                    {isDashboard ?
                        <CustomButton
                            content="Proceed"
                            classes="primary"
                            config={{ type: 'button' }}
                            onClick={handleProceed}
                            disabled={isEmpty(selectedPack)}
                        />
                        :
                        <CustomButton
                            content="Next"
                            classes="primary"
                            config={{ type: 'button' }}
                            onClick={handleNext}
                            disabled={isEmpty(selectedPack)}
                        />}
                        </div>
                </div>
            </div>
        </div>
    )
}

export default TpCorporatePacks;