import React,{Component} from 'react';
import './index.scss'
import Logo from './../../../assets/images/tata-sky-logo-black.png';
import Login from './Login';

class Admin extends Component {
    state = {
        activeTab : 0,
    };

    handleSelect = (index, obj) => {
        this.setState({
            activeTab: index,
        })
    };

    render() {
        const { activeTab } = this.state;
        return (
            <div className="auth-container">
                <div className="wrapper">
                    <img src={Logo} alt="Tata Play" className="logo mb10" />
                    <div className="row mrl0 justify-content-center">
                        <div className="col-12 col-xl-5 prl0 ">
                            <div className="form-container ptb20">
                                <ul className="list-tab mb20 justify-content-center" style={{padding:0}}>
                                    <li
                                        className={activeTab === 0 ? 'active' : ''}
                                        onClick={() =>this.handleSelect(0)}
                                    >
                                        Admin Login
                                    </li>
                                    {/* <li
                                        className={activeTab === 1 ? 'active' : ''}
                                        onClick={() =>this.handleSelect(1)}
                                    >
                                        Admin Login
                                    </li> */}
                                </ul>
                                <div className="inner prl30">
                                    <div className="description prl20 pb25">
                                    To manage Tata Play B2B accounts, login through your                                         <span className="medium-strong prl5">
                                            Registered Mobile Number
                                        </span>
                                        or
                                        <span className="medium-strong pl5">
                                            Email ID
                                        </span>
                                    </div>
                                </div>
                
                                      <Login handleSelect={this.handleSelect}/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default Admin;