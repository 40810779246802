import React, {Component} from 'react';
import {connect} from 'react-redux';
import './login.scss';
import Tooltip from "../../Common/Tooltip";
import {withRouter} from 'react-router-dom';
import {isAuthenticated} from '../../../helper';
import {bindActionCreators} from "redux";
import Modal from "../../Common/Modal";
import {MODALS} from '../../../storeManager/modal/constants';
import {openModal as openModalHandler} from "../../../storeManager/modal/actions";
import InputsValidator from "../../../services/FormValidator/InputsValidator";
import {get, debounce} from "lodash";
import {commonService} from "../../../storeManager/common/services";
import GlobalConfig from "../../../globalConfig/globalConfig";
import Loader from "../../Loader";
import {API_REQUEST_KEYS, PATHS} from "../../../constants";
import Error from "../../Common/Error";
import {restrictDecimalInput} from './../../../helper';
// import ReCaptcha from "../../Common/GoogleCaptcha/googleCaptcha";

class Login extends Component {
    updateError = null;
    state = {
        registeredMobileNumber: '',
        chainId: '',
        activeLoginTab: 0,
        success: '',
        error: {},
        errorMsg: '',
        isLoading: false,
        // captchaToken: null
    };

    componentDidMount() {
        const {history} = this.props;
        if (isAuthenticated()) {
            history.push(PATHS.HOME)
        }
        this.loginInput.focus();
    }

    selectLoginTab = (index) => {
        const {activeLoginTab} = this.state;
        this.loginInput.focus();
        if (activeLoginTab !== index) {
            this.setState({
                activeLoginTab: index,
                registeredMobileNumber: '',
                chainId: '',
                error: '',
                errorMsg: ''
            })
        }
    };

    loginInputChangeHandler = (event) => {
        const {error, errorMsg} = this.state;
        (error || errorMsg) && this.setState({error: {}, errorMsg: ''});
        let {name, value} = event.target;
        value = value.trim();
        if (!isNaN(value)) {
            const updateState = {[name]: value};
            this.setState(updateState, () => {
                get(this.updateError, 'cancel') && this.updateError.cancel();
                this.updateError = debounce(() => {
                    if (value.length === 10) {
                        const error = this.validateInput(name);
                        this.setState({
                            error: {
                                [name]: error
                            }
                        })
                    }
                }, 0);
                this.updateError();
            });
        }
    };

    validateInput = (name) => {
        const {
            [name]: validateKey
        } = this.state;
        switch (name) {
            case 'chainId':
                return InputsValidator.validateChainId(validateKey) || {};
            case 'registeredMobileNumber':
                return InputsValidator.validatePhoneNumber(validateKey) || {};
            default:
                return null;
        }

        /*let definedMsg = (
          <div>
             <span className="title pr5">
                Sorry!
             </span>
            The mobile number you have entered is not registered in our system.
            Please check the number and re-enter or try logging in with your
            Subscriber ID.
          </div>
        );*/
    };

    authenticateUser = (event) => {
        if (!isAuthenticated()) {
            this.setState({isLoading: true});
            event.preventDefault();
            const {registeredMobileNumber, chainId} = this.state;
            const {openModal} = this.props;
            if (registeredMobileNumber) {
                this.getChainIdList(registeredMobileNumber);
            } else {
                //chainId Popup
                this.setState({isLoading: false});
                openModal(MODALS.LOGIN_OTP,
                    {
                        chainId
                    });
            }


        } else {
            const {history} = this.props;
            history.push(PATHS.HOME);
        }
    };

    getChainIdList = async (mobileNumber) => {
        const {openModal, closeModal} = this.props;
        const payload = {
            "accountDetails": {
                "rmn": mobileNumber,
                "requestType": API_REQUEST_KEYS.requestType.chainValidation
            }
        };
        try {
            const response = await commonService.chainIdListApi(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({isLoading: false});
                if(responseData.data) {
                    let subscriberList = responseData.data.subscriberList;
                    if (subscriberList.length > 1) {
                        openModal(MODALS.MULTIPLE_CHAIN_ID, {chainIdList: subscriberList, mobileNumber, mW600: "mW600"});
                    } else {
                        openModal(MODALS.LOGIN_OTP, {chainId: subscriberList[0].subscriberId, mobileNumber});
                    }
                } else {
                    openModal(MODALS.LOGIN_OTP,
                        {
                            mobileNumber
                        });
                }

            } else {
                localStorage.clear();
                closeModal();
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };

    render() {
        const {registeredMobileNumber, chainId, activeLoginTab, error, success, isLoading, errorMsg} = this.state;
        const {selectLoginTab, loginInputChangeHandler, authenticateUser} = this;
        return (
            <div className="login-container">
                {
                    isLoading && <Loader/>
                }
                <div className="inner prl30">
                    <div className="description prl20 pb25">
                        To manage your Tata Play account, login through your
                        <span className="medium-strong prl5">
                            Registered Mobile Number
                        </span>
                        or
                        <span className="medium-strong pl5">
                            Chain ID
                        </span>
                    </div>
                </div>

                <ul className="login-tab mb30 prl30">
                    <li
                        className={activeLoginTab === 0 ? 'active' : ''}
                        onClick={() => selectLoginTab(0)}
                    >
                        <i className="icon">
                            <svg width="11" height="22">
                                <title>RMN</title>
                                <path fill="currentColor" fillRule="evenodd"
                                      d="M9.429.28h-7.42C1.299.28.726.965.726 1.81v17.944c0 .844.573 1.529 1.283 1.529h7.42c.708 0 1.282-.685 1.282-1.529V1.81c0-.845-.574-1.53-1.282-1.53zm-5.541.929h3.663c.092 0 .167.164.167.368s-.075.369-.167.369H3.888c-.093 0-.167-.165-.167-.369s.074-.368.167-.368zM5.72 19.773c-.452 0-.82-.438-.82-.977 0-.538.368-.975.82-.975.45 0 .817.437.817.975 0 .539-.367.977-.817.977zm4.03-3.344H1.688V2.861H9.75v13.568z"/>
                            </svg>
                        </i>
                        Registered Mobile Number
                    </li>
                    <li
                        className={activeLoginTab === 1 ? 'active' : ''}
                        onClick={() => selectLoginTab(1)}
                    >
                        <i className="icon">
                            <svg width="14" height="21">
                                <title>Chain ID</title>
                                <path fill="currentColor" fillRule="evenodd"
                                      d="M12.49 0H1.51C.676.001.001.739 0 1.65v17.7c.001.911.676 1.649 1.51 1.65h10.98c.834-.001 1.509-.739 1.51-1.65V1.65C13.999.739 13.324.001 12.49 0zM5.092 1.655h3.66c.177 0 .321.157.321.35 0 .193-.144.35-.321.35h-3.66c-.177 0-.32-.157-.32-.35 0-.193.143-.35.32-.35zM5.03 9.627a3.327 3.327 0 0 1-.078-.305c-.062-.35-.078-.672-.005-.983a2.24 2.24 0 0 1 .522-1.006c.145-.17.311-.317.486-.441.145-.107.3-.198.471-.266.134-.05.279-.079.429-.09.465-.045.822.085 1.076.249.377.231.522.531.522.531s.869.068.574 2a1.715 1.715 0 0 1-.077.306c.176-.017.377.09.186.836-.14.542-.275.695-.373.706-.093.645-.563 1.47-1.303 1.758a1.325 1.325 0 0 1-.956 0c-.755-.288-1.21-1.113-1.304-1.763-.098-.011-.232-.164-.372-.707-.176-.734.031-.847.202-.825zM7.015 17.2h-4.24c.021-1.921-.046-2.95 1.091-3.402 1.035-.435 1.655-.876 1.655-.876l.797 2.758.108.373.357-1.108c-.823-1.254.062-1.311.217-1.311s1.035.062.217 1.311l.357 1.108.109-.373.796-2.758s.621.441 1.655.876c1.143.452 1.07 1.481 1.091 3.402h-4.21z"/>
                            </svg>
                        </i>
                        Chain ID
                    </li>
                </ul>
                <form onSubmit={(e) => authenticateUser(e)}>
                    {
                        activeLoginTab === 0 ? (
                            <div
                                className={(get(error, 'registeredMobileNumber.error')) ? 'form-element mb30 error' : 'form-element mb30'}>
                                <input
                                    type="text"
                                    className="input-element"
                                    placeholder="Enter 10 digit mobile number"
                                    // autoComplete="off"
                                    maxLength={10}
                                    name="registeredMobileNumber"
                                    value={registeredMobileNumber}
                                    ref={(input) => {
                                        this.loginInput = input;
                                    }}
                                    onChange={loginInputChangeHandler}
                                    onKeyPress={restrictDecimalInput}
                                />
                                {
                                    get(error, 'registeredMobileNumber.error') ? (
                                        <div className="error-section">
                                            <i className="icon">
                                                <svg width="20" height="20" viewBox="0 0 25 25">
                                                    <defs>
                                                        <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                            <stop offset="0%" stopColor="#A63283"/>
                                                            <stop offset="100%" stopColor="#EA1B2D"/>
                                                        </linearGradient>
                                                    </defs>
                                                    <path fill="#51B684" fillRule="evenodd"
                                                          d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                    <path fill="url(#error)"
                                                          d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                </svg>
                                            </i>
                                            <div className="error-message">
                                                {
                                                    get(error, 'registeredMobileNumber.message')
                                                }
                                            </div>
                                        </div>
                                    ) : success ? (
                                        <div className="success-section">
                                            <i className="icon">
                                                <svg width="22" height="16" viewBox="0 0 27 19">
                                                    <path fill="#51B684" fillRule="evenodd"
                                                          d="M27 2.471c-.134.4-.405.703-.71.994-1.743 1.668-3.478 3.344-5.218 5.015-2.12 2.036-4.242 4.07-6.364 6.104-1.136 1.089-2.275 2.176-3.411 3.264-.858.821-1.823.832-2.688.006-1.65-1.577-3.29-3.164-4.936-4.745-.979-.94-1.957-1.881-2.946-2.811C.407 9.996.135 9.673 0 9.254V8.54c.329-.854.915-1.301 1.694-1.283.521.012.942.237 1.304.586 1.599 1.54 3.199 3.081 4.8 4.619.642.617 1.293 1.224 1.928 1.846.173.169.277.183.46.004 1.331-1.295 2.673-2.581 4.013-3.868a9984.84 9984.84 0 0 1 6.642-6.371c1.054-1.011 2.138-1.994 3.158-3.036.928-.949 2.558-.686 2.966.6.008.025.023.046.035.069v.765z"/>
                                                </svg>
                                            </i>
                                        </div>
                                    ) : null
                                }
                                {/*<ReCaptcha
                                    id="recaptcha-rmn"
                                    getRef={ref => {
                                        this.captchaElement = ref;
                                    }}
                                    onChange={this.onCaptchaResolved}
                                    resetId={id => {
                                        this.captchaElementResetId = id;
                                    }}
                                />*/}
                            </div>
                        ) : (
                            <div
                                className={(get(error, 'chainId.error')) ? "form-element error mb30" : "form-element mb30"}>
                                <input
                                    type="text"
                                    className="input-element"
                                    placeholder="Enter your Chain ID"
                                    maxLength="10"
                                    name="chainId"
                                    value={chainId}
                                    autoComplete="off"
                                    ref={(input) => {
                                        this.loginInput = input;
                                    }}
                                    onChange={loginInputChangeHandler}
                                    onKeyPress={restrictDecimalInput}
                                />
                                <i className="info-icon">
                                    <svg width="20" height="20" viewBox="0 0 28 28">
                                        <defs>
                                            <linearGradient id="infoGradient" x1="100%" x2="0%" y1="0%" y2="0%">
                                                <stop offset="0%" stopColor="#A63283"/>
                                                <stop offset="100%" stopColor="#EA1B2D"/>
                                            </linearGradient>
                                        </defs>
                                        <path fill="#CCC" fillRule="evenodd"
                                              d="M13.515.014C6.059.014.014 6.059.014 13.514c0 7.457 6.045 13.502 13.501 13.502 7.456 0 13.501-6.045 13.501-13.502 0-7.455-6.045-13.5-13.501-13.5zm2.81 20.924c-.694.274-1.248.482-1.663.626-.413.144-.894.217-1.442.217-.842 0-1.496-.208-1.963-.616-.466-.411-.698-.931-.698-1.563 0-.246.017-.498.051-.753.036-.256.092-.544.168-.868l.87-3.071c.077-.297.143-.577.195-.838.053-.263.078-.504.078-.723 0-.391-.081-.666-.242-.82-.163-.154-.471-.23-.929-.23-.224 0-.455.033-.692.103a9.249 9.249 0 0 0-.605.202l.23-.947a21.55 21.55 0 0 1 1.635-.595 4.825 4.825 0 0 1 1.475-.25c.835 0 1.479.204 1.934.606.451.404.679.928.679 1.573 0 .134-.016.369-.048.705a4.735 4.735 0 0 1-.174.928l-.865 3.063c-.07.246-.134.527-.19.84a4.36 4.36 0 0 0-.084.718c0 .405.09.683.273.832.181.147.498.222.944.222.212 0 .449-.038.717-.113.266-.073.458-.139.578-.194l-.232.946zm-.153-12.433a2.068 2.068 0 0 1-1.458.562 2.09 2.09 0 0 1-1.462-.562c-.405-.375-.61-.831-.61-1.364 0-.531.206-.988.61-1.367a2.073 2.073 0 0 1 1.462-.568c.569 0 1.055.189 1.458.568.403.379.606.836.606 1.367 0 .534-.203.989-.606 1.364z"/>
                                    </svg>
                                </i>
                                {
                                    get(error, 'chainId.error') ? (
                                        <div className="error-section">
                                            <i className="icon">
                                                <svg width="20" height="20" viewBox="0 0 25 25">
                                                    <defs>
                                                        <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                            <stop offset="0%" stopColor="#A63283"/>
                                                            <stop offset="100%" stopColor="#EA1B2D"/>
                                                        </linearGradient>
                                                    </defs>
                                                    <path fill="#51B684" fillRule="evenodd"
                                                          d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                    <path fill="url(#error)"
                                                          d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                </svg>
                                            </i>
                                            <div className="error-message">
                                                {
                                                    get(error, 'chainId.message')
                                                }
                                            </div>
                                        </div>
                                    ) : null
                                }
                                <Tooltip
                                    message="Please Enter your Chain ID."
                                />
                            </div>
                        )
                    }

                    {
                        errorMsg && <Error error={errorMsg}/>
                    }

                    <button
                        type="submit"
                        className="button fill full big mt30"
                        onClick={(e) => authenticateUser(e)}
                        disabled={
                            activeLoginTab === 0 ? (
                                    !registeredMobileNumber || registeredMobileNumber.length < 10 ||
                                    (get(error, 'registeredMobileNumber.error'))
                                ) :
                                (!chainId || (get(error, 'chainId.error')) || chainId.length < 10)
                        }
                    >
                        Continue
                    </button>
                </form>
                <Modal/>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default withRouter(connect(null, mapDispatchToProps)(Login));