import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from "../../../constants";
import logo from '../../../assets/images/tata-sky-logo-black.png';
import './header.scss';

// dependent components
import Navbar from './Navbar';
import Profile from "./Profile";

const Header = () => {
  return (
    <header className="header">
      <div className="wrapper">
        <div className="inner">
          <Link to={PATHS.HOME}>
            <img src={logo} alt="Tata Play" className="logo" />
          </Link>
          <Navbar />

          <div className="d-inline-flex ml-auto">
            {/*<Link
                               to={{
                                   pathname:PATHS.SERVICE_REQUEST,
                               }}
                           >
                               <button
                                   className="button fill big link-btn mr30"
                               >
                                   Service Request
                               </button>
                           </Link>*/}
            <Profile />
          </div>
        </div>
      </div>
    </header>
  )
};

export default Header;