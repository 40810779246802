import React, { useEffect, useState } from 'react'
// import ContentLoader from '../Common/ContentLoader'
import './style.css'
// import retry from '../../assets/images/retry.svg'
import { commonService } from '../../storeManager/common/services'
import GlobalConfig from '../../globalConfig/globalConfig'
import { MESSAGES, API_REQUEST_KEYS } from "../../constants";
import Modal from '../Common/Modal'
import { PATHS } from '../../constants'
import { useHistory } from 'react-router-dom'
import { MODALS } from '../../storeManager/modal/constants'
import { openModal } from '../../storeManager/modal/actions'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import TableWithAction from '../TableWithAction'
import CustomButton from '../Common/Button'
import { setSession } from '../../services/commonUtils'
import Loader from '../Loader'
import ReactLoading from 'react-loading';
import { hideToastInfo, showToastInfo } from '../../storeManager/common/actions';
import ToastInfo from '../Common/ToastInfo';
import { getDateFormatYYYYMMDD } from "../../helper";


function Dashboard() {
  const [showMore, setShowMore] = useState(false);
  const [pageCount, setpageCount] = useState('');
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [accountError, setaccountError] = useState('');
  const [serviceReqError, setServiceReqError] = useState('');
  const [showServiceReload, setShowServiceReload] = useState(false);
  const [serviceErrorMsg, setServiceErrorMsg] = useState('');
  const [accountdata, setAccountdata] = useState();
  const [serviceReqData, setServiceReqData] = useState();
  const [boxlistData, setBoxlistData] = useState({ boxlist: [] });
  const [selectedStbId, setSelectedStbId] = useState([]);
  const [selectedSubId, setSelectedSubId] = useState([]);
  const [filter, setFilter] = useState('All')
  const history = useHistory();
  const dispatch = useDispatch();
  let count = pageCount || 0
  let updateData = [...boxlistData.boxlist];

  const recomendationParamInput = React.createRef();
  const getThisForm = React.createRef();

  useEffect(() => {
    getBoxIdList();
    accountSummary();
    serviceRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const accountSummary = async () => {
    setPageLoading(true)
    try {
      const response = await commonService.accountSummary();
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        setAccountdata(responseData.data)
        setPageLoading(false)
      } else {
        setaccountError(true)
        setPageLoading(false)
      }
    } catch {
      console.error(MESSAGES.DID_MOUNT_ERROR);
      setPageLoading(false)
    }
  }

  const serviceRequest = async () => {
    setPageLoading(true)
    try {
      const response = await commonService.serviceRequest();
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        setServiceReqData(responseData.data)
        setServiceErrorMsg('')
        setShowServiceReload(false)
        setPageLoading(false)
      } else {
        setServiceReqError(true)
        if (responseData.message === 'NO_DATA_FOUND'){
          setServiceErrorMsg('No Service Request Found')
          setShowServiceReload(false)
        }
        else{
          setServiceErrorMsg('Something went wrong')
          setShowServiceReload(true)
        }
        setPageLoading(false)
      }
    } catch {
      console.error(MESSAGES.DID_MOUNT_ERROR);
      setPageLoading(false)
    }
  }


  const getBoxIdList = async () => {
    setLoading(true)
    const payload = {
      "accountDetails": {
        "noOfRows": 200,
        "page": count,
        "requestType": API_REQUEST_KEYS.requestType.getBoxList,
        "viewRequestType": "DASHBOARD"
      }
    };
    try {
      const response = await commonService.subscriberListApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        let data = responseData.data;
        if (Array.isArray(data.subscriberList)) {
          updateData = updateData.concat(data.subscriberList);
          // setNewBoxData(updateData)
          setBoxlistData({
            boxlist: updateData,
            noDataFound: data.noDataFound,
            isLoading: false,
            hasMoreItems: data.hasMoreItems,
            loadMoreLoader: false,
          })
          if (data.hasMoreItems && count < 4 && !showMore) {
            count = count + 1
            getBoxIdList()
          } else if (!data.hasMoreItems) {
            setShowMore(false)
            setLoading(false)
          } else {
            setpageCount(count + 1)
            setShowMore(true)
            setLoading(false)
          }
        } else if (isEmpty(data.subscriberList) || !data.hasMoreItems) {
          setpageCount(0)
          setShowMore(false)
          setLoading(false)
        }
        if (count > 4)
          window.scrollTo({ top: document.body.scrollHeight - 800, behavior: 'smooth' })
      } else {
        setLoading(false)
        dispatch(openModal(MODALS.CONFIRMATION_POPUP,
          {
            message: responseData.message,
            Dashboard: true,
            reDirectLinkHide: true,
            getBoxIdList,
          }))
        // this.setState({ error: responseData.message, isLoading: false, hasMoreItems: false, loadMoreLoader: false })
      }
    } catch {
      setLoading(false)
      // this.setState({ error: GlobalConfig.serverError, isLoading: false, hasMoreItems: false, loadMoreLoader: false })
    }
  };
  const handleBalkOperation = () => {
    setSession("sid", selectedSubId)
    history.push(
      {
        pathname: PATHS.BULK_MODIFY,
        isDashboard: true,
      }
    )
  }

  const handleBulkSuspend = (e, operation) => {
    // sessionStorage.setItem("bulksubid", JSON.stringify(selectedSubId))
    if (operation === 'Resume') {
      handleBulkResume(operation)
    } else {
      dispatch(openModal(MODALS.BULK_SUSPEND_MODAL,
        {
          operation,
          boxlistData: boxlistData.boxlist,
          selectedStbId,
          selectedSubId
        }))
    }
  }
  const handleBulkResume = async (operation) => {
    setPageLoading(true)
    const payload = {
      "subIdStb": selectedSubId.map(item => item),
      "resDate": getDateFormatYYYYMMDD(new Date()),
      "stbType": boxlistData.boxlist.find(f => f.stbNumber === selectedStbId[0]).connectionType,
      "actionType": operation,
      "activityType": `Bulk ${operation}`,
      "susDate": ""
    };

    try {
      const response = await commonService.bulkSuspend(payload);
      const responseData = response.data;
      setSession('bulkOpetaionResponse', responseData)
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        // const data = responseData.data;
        setPageLoading(false)
        history.push('/operation-success')
      }
    } catch {
      //   console.error(MESSAGES.DID_MOUNT_ERROR);
      setPageLoading(false)
    }
  }
  const redirectToTrackReq = () => {
    history.push({
      pathname: PATHS.SERVICE_REQUEST,
      state: {
        subId: null,
        noOfRows: null,
        trackreq: true
      }
    })
  }
  // handle for Sub ids selection 
  const handleSelectSubId = (e, value, isAllSelected = false) => {
    let updatedStbId = [...selectedStbId];
    let updatedSubId = [...selectedSubId];
    dispatch(hideToastInfo())
    if (isAllSelected) {
      const stbIdList = [];
      const subIdList = [];
      if (value.length > 250) {
        value.slice(0, 250).forEach(el => {
          stbIdList.push(el.stbNumber)
          subIdList.push(`${el.subscriberId}|${el.stbNumber}`)

        })

        dispatch(showToastInfo("First 250 records have been selected, You can not select more than 250 records", 5000))
      } else {
        value.forEach(el => {
          stbIdList.push(el.stbNumber)
          subIdList.push(`${el.subscriberId}|${el.stbNumber}`)

        })
      }
      setSelectedStbId(stbIdList)
      setSelectedSubId(subIdList)
    }
    else {
      if (e.target.checked) {
        if (updatedStbId.length < 250) {
          updatedStbId.push(value.stbNumber);
          updatedSubId.push(`${value.subscriberId}|${value.stbNumber}`);
        } else {
          dispatch(showToastInfo("You can not select more than 250 records", 5000))
        }
      }
      else {
        updatedStbId = updatedStbId.filter(o => o !== value.stbNumber);
        updatedSubId = updatedSubId.filter(o => o !== `${value.subscriberId}|${value.stbNumber}`);
      }
      setSelectedStbId(updatedStbId)
      setSelectedSubId(updatedSubId)
    }

  }
  const reselectedSubId = () => {
    setSelectedStbId([])
    setSelectedSubId([])
    document.getElementById('SelectAllCheckbox').checked = false;
  }
  const getFilter = (filter) => {
    setFilter(filter)
  }
  const updatedBoxName = (updatedData) => {
    setBoxlistData(updatedData)
  }
  if (pageLoading)
    return (<Loader />)
  return (
    <>
      <div className="b2b-dashboard">
        <form method="post" ref={getThisForm} className="d-none" target='_blank'>
          <textarea ref={recomendationParamInput} name="recomendationParam" />
        </form>
        <div className="container-wrapper ">
          {accountError && <div className="accountSummaryError">
            <h4>Boxdata did not load. </h4>
            <button className='reset-link' onClick={accountSummary}>
              Retry</button>
          </div>}
          <div className='system-overview'>
            <div className='overview-section1'>
              <div className='overview-section1-box1'><h5>System Overview - Primary Boxes</h5>
                <div className='overview-section1-boxElement'>
                  <div className='overview-section1-boxElement-item1'><span>{accountdata ? accountdata.primaryBoxes.active : '-'}</span> <h4>Active</h4></div>
                  <div className='overview-section1-boxElement-item2'><span>{accountdata ? accountdata.primaryBoxes.suspended : '-'}</span> <h4>Suspended</h4></div>
                </div>
              </div>
              <div className='overview-section1-box2 mL10'><h5>System Overview - Secondary Boxes</h5>
                <div className='overview-section1-boxElement'>
                  <div className='overview-section1-boxElement-item1'><span>{accountdata ? accountdata.secondaryBoxes.active : '-'}</span> <h4>Active</h4></div>
                  <div className='overview-section1-boxElement-item2'><span>{accountdata ? accountdata.secondaryBoxes.suspended : '-'}</span> <h4>Suspended</h4></div>
                </div>
              </div>

            </div>
            <div className='overview-section2'><h5>System Overview - Account Level Status</h5>
              <div className='overview-section2-boxElement'>
                <div className='overview-section2-boxElement-item1'><span>{accountdata ? accountdata.accountDetails.active : '-'}</span> <h4>Active</h4></div>
                <div className='overview-section2-boxElement-item2'><span>{accountdata ? accountdata.accountDetails.deactivated : '-'}</span> <h4>Deactive</h4> </div>
                <div className='overview-section2-boxElement-item3'><span>{accountdata ? accountdata.accountDetails.others : '-'}</span> <h4>Others</h4></div>
              </div>
            </div>
          </div>
          <div className="service-request">
            <div className="service-request-left-section">
              <div className="ser-req"><h4>Service Request</h4></div>
              {serviceReqError && <div className="servicerequestError">
                <h4>{serviceErrorMsg}</h4>
                {showServiceReload && <button className='reset-link' onClick={serviceRequest}>
                  Retry</button>}
              </div>}
              {!serviceReqError && <div className="service-request-left-section-item s-req1"><h4>Ongoing - {serviceReqData && serviceReqData.ongoing}</h4></div>}
              {!serviceReqError && <div className="service-request-left-section-item s-req2"><h4>Resolved - {serviceReqData && serviceReqData.resolved}</h4></div>}
              {!serviceReqError && <div className="service-request-left-section-item s-req3"><h4>Cancelled - {serviceReqData && serviceReqData.cancelled}</h4></div>}
            </div>
            <div className="service-request-trackBtn">
              <button className="btn-primary" onClick={redirectToTrackReq}>Track Request</button>
            </div>
          </div>
        </div>
        <div className="bottom-section ">
          <div className="subscriber-details">
            <div className='dashboard-table-wrap'>
              <div className='search-wrapper'>
                <TableWithAction
                  selectedStbId={selectedStbId}
                  handleSelectSubId={handleSelectSubId}
                  getFilter={getFilter}
                  boxlistData={boxlistData}
                  updatedBoxName={updatedBoxName}
                  loading={loading}
                  reselectedSubId={reselectedSubId}
                  recomendationParamInput={recomendationParamInput}
                  getThisForm={getThisForm}
                />
                {/*showMore
                  && <div className='text-center-flex'>
                    <CustomButton
                      content="Load More"
                      classes="primary"
                      config={{ type: 'button', class: 'secondary' }}
                      onClick={getBoxIdList}
                    />
  </div>*/}
              </div>
            </div>
          </div>
        </div>

        <div className='bulkOperationFooter'>
          {/*selectedStbId.length > 0 && <span className='in-footer box-text small'>Selected Boxes ({selectedStbId.length})</span>*/}
          <div className='footerWrapper'>
            {showMore && <div className='bulkOperationFooter-loadmore-sec'>
              <CustomButton
                classes="primary"
                config={{ type: 'button', class: 'secondary' }}
                onClick={getBoxIdList}
              >
                {loading ? <ReactLoading type={'bars'} color={'#E10092'} height={22} width={25} /> : `Load More Boxes (${boxlistData && boxlistData.boxlist.length})`}
              </CustomButton>
            </div>}
            <div className='bulkOperationFooter-text'>Perform Bulk Operations by selecting boxes</div>
            <div className='bulkOperationFooter-button'>
              <CustomButton
                content="Suspend"
                classes="primary"
                config={{ type: 'button' }}
                onClick={e => handleBulkSuspend(e, 'Suspend')}
                disabled={filter === 'All' || selectedStbId.length < 2}
              />
              <CustomButton
                content="Resume"
                classes="primary"
                config={{ type: 'button' }}
                onClick={e => handleBulkSuspend(e, 'Resume')}
                disabled={filter === 'All' || selectedStbId.length < 2}
              />
              <CustomButton
                content="Bulk Pack Modify"
                classes="primary"
                config={{ type: 'button' }}
                onClick={handleBalkOperation}
                disabled={selectedStbId.length < 2}
              />
            </div>
          </div>
        </div>
        <Modal />
        <ToastInfo />
      </div>
    </>
  )
}


export default Dashboard;
