import React from 'react';
import logo from '../../assets/images/tata-sky-logo-black.png';
import {PATHS} from "../../constants";
import {Link} from "react-router-dom";
import './no-page-found.scss'
import {isAdminAuthenticated} from '../../helper';

const NoPageFound = (props) => {
  
    return (
        <div className="page-error-container">
            <div className="wrapper">
               <div className="mb60 ptb25">
                   <Link to={PATHS.HOME}>
                       <img src={logo} alt="Tata Play" className="logo" />
                   </Link>
               </div>

              <div className="inner pb60">
                <div className="mb30">
                  <h2 className="heading">
                    Error 404
                  </h2>
                </div>

                <div className="description mb50">
                  Oops! The page you want has been moved or doesn’t exist
                </div>

                <i className="error-icon">
                  <svg width="100" height="100" viewBox="0 0 90.4 88.4">
                    <linearGradient id="a-error" gradientUnits="userSpaceOnUse" x1="23.759" y1="60.656" x2="67.179" y2="60.656">
                      <stop offset="0" stopColor="#ff9000"/>
                      <stop offset=".489" stopColor="red"/>
                      <stop offset="1" stopColor="#8e00b2"/>
                    </linearGradient>
                    <path d="M60.9 56.8c-4.1-3.3-9.6-5.1-15.5-5.1-12 0-21.7 7.6-21.7 17 0 .5.4.9.9.9s.9-.4.9-.9c0-8.4 9-15.2 20-15.2s20 6.8 20 15.2c0 .5.4.9.9.9s.9-.4.9-.9c-.1-4.5-2.3-8.7-6.4-11.9z" fill="url(#a-error)"/>
                    <linearGradient id="b-error" gradientUnits="userSpaceOnUse" x1="23.747" y1="35.057" x2="34.679" y2="35.057">
                      <stop offset="0" stopColor="#ff9000"/>
                      <stop offset=".489" stopColor="red"/>
                      <stop offset="1" stopColor="#8e00b2"/>
                    </linearGradient>
                    <path d="M28.4 40.5c1.7 0 3.2-.6 4.4-1.8 1.2-1.2 1.8-2.8 1.8-4.4s-.6-3.3-1.8-4.5c-.3-.3-.9-.3-1.2 0-.3.3-.3.9 0 1.2 1.8 1.8 1.8 4.6 0 6.4-1.7 1.7-4.7 1.7-6.4 0-.2-.2-.4-.3-.6-.3s-.4.1-.6.3c-.2.2-.3.4-.3.6s.1.4.3.6c1.2 1.3 2.7 1.9 4.4 1.9z" fill="url(#b-error)"/>
                    <linearGradient id="c-error" gradientUnits="userSpaceOnUse" x1="56.26" y1="35.054" x2="67.193" y2="35.054">
                      <stop offset="0" stopColor="#ff9000"/>
                      <stop offset=".489" stopColor="red"/>
                      <stop offset="1" stopColor="#8e00b2"/>
                    </linearGradient>
                    <path d="M65.7 37.5c-1.8 1.8-4.6 1.8-6.4 0-.9-.9-1.3-2-1.3-3.2 0-1.2.5-2.3 1.3-3.2.3-.3.3-.9 0-1.2-.2-.2-.4-.3-.6-.3-.2 0-.5.1-.6.3-2.4 2.4-2.4 6.4 0 8.8 1.2 1.2 2.8 1.8 4.4 1.8 1.7 0 3.2-.6 4.4-1.8.2-.2.3-.4.3-.6s-.1-.4-.3-.6c-.3-.4-.9-.4-1.2 0z" fill="url(#c-error)"/>
                    <linearGradient id="d-error" gradientUnits="userSpaceOnUse" x1="1.97" y1="44.077" x2="88.97" y2="44.077">
                      <stop offset="0" stopColor="#ff9000"/>
                      <stop offset=".489" stopColor="red"/>
                      <stop offset="1" stopColor="#8e00b2"/>
                    </linearGradient>
                    <path d="M45.5.6C21.5.6 2 20.1 2 44.1s19.5 43.5 43.5 43.5S89 68.1 89 44.1 69.5.6 45.5.6zm0 85.2c-23 0-41.8-18.7-41.8-41.8S22.4 2.3 45.5 2.3 87.2 21 87.2 44.1 68.5 85.8 45.5 85.8z" fill="url(#d-error)"/>
                  </svg>
                </i>

                <div className="ptb20">
                  <Link to={isAdminAuthenticated() ? PATHS.ADMIN_DASHBOARD: PATHS.HOME} className="link">
                    Go to Home Page
                  </Link>
                </div>
              </div>
            </div>
        </div>
    )
};

export default NoPageFound;