import {ACTION} from "./constants";

export const resetReduxState = () => (dispatch) => {
  dispatch({
    type: ACTION.RESET_STATE,
  })
};
export const showToastInfo = (message,time) => (
  { type: ACTION.SHOW_TOAST_INFO, data: {message,time} }
);
export const hideToastInfo = () => (
  { type: ACTION.HIDE_TOAST_INFO }
);
/*
export const logout = () => (dispatch) => {
  dispatch({
    type: ACTION.LOGOUT,
  })
};*/
