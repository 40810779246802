export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const MODALS = {
  LOGIN_OTP: 'LOGIN_OTP',
  BALANCE_DETAILS: 'BALANCE_DETAILS',
  MULTIPLE_CHAIN_ID: 'MULTIPLE_CHAIN_ID',
  CONFIRMATION_POPUP: 'CONFIRMATION_POPUP',
  ACTIVITIES_DETAILS: 'ACTIVITIES_DETAILS',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  ADMIN_OTP_MODAL: 'ADMIN_OTP_MODAL',
  ADMIN_EDIT_USER: 'ADMIN_EDIT_USER',
  OTP_MODAL: 'OTP_MODAL',
  BULK_SUSPEND_MODAL: 'BULK_SUSPEND_MODAL'
};
