import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './style.css';
import arrow from '../../../assets/images/right-arrow-blue.svg'
import Balance from '../../../assets/images/recharge.svg'
// import transhistory from '../../../assets/images/transaction-history.svg'
// import raisereq from '../../../assets/images/get-help-assistant.svg'
import close from '../../../assets/images/close-pink.svg'
// import retry from '../../../assets/images/retry.svg'
import { commonService } from '../../../storeManager/common/services'
import GlobalConfig from '../../../globalConfig/globalConfig'
import { PATHS } from "../../../constants";
import ContentLoader from '../../Common/ContentLoader'
import { dateFormatter } from '../../../helper';


function MoreInfoModal(props) {
  const { show, handleClose, subscriberId, connectionLength, hardReset, connectionType, heavyRefresh } = props
  const [balance, setBalance] = useState(false);
  const [balanceerror, setBalanceerror] = useState(false);
  const [isHardReset, setIsHardReset] = useState(hardReset);
  const [isLoading, setIsLoading] = useState(false);
  // const history = useHistory();
  // const ThirtyDayMS = 2592000000;

  useEffect(() => {
    getBalanceDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBalanceDetails = async () => {
    setIsLoading(true)
    setBalanceerror(false)
    const payload = {
      subscriberId,
      noOfRows: 25
    };
    try {
      const response = await commonService.balanceDetailApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        const updatedResponseObj = responseData.data;
        setBalance(updatedResponseObj)
        setIsLoading(false)
      } else {
        setBalanceerror(responseData.message)
        setIsLoading(false)
        // this.setState({ error: responseData.message, isLoading: false })
      }
    } catch {
      setBalanceerror(GlobalConfig.serverError)
      setIsLoading(false)
      // this.setState({ error: GlobalConfig.serverError, isLoading: false })
    }
  };
 
  const handleHardReset = async () => {
    const payload = {
      "subscriberId": subscriberId,
      "noOfRows": 200
    };
    try {
      const response = await commonService.hardResetApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        heavyRefresh(subscriberId)
        setIsHardReset(false)
      }
      /*else {
        this.setState({error: responseData.message})
      }*/
    } catch {
      // this.setState({error: GlobalConfig.serverError})
    }
  }
  // const redirectUrl = (type) => {
  //   if (type === 'transhistory') {
  //     history.push({
  //       pathname: PATHS.TRANSACTION_HISTORY,
  //       state: {
  //         subscriberId: subscriberId,
  //         noOfRows: connectionLength
  //       }
  //     })
  //   } else {
  //     history.push({
  //       pathname: PATHS.SERVICE_REQUEST,
  //       state: {
  //         subId: subscriberId,
  //         noOfRows: connectionLength
  //       }
  //     })
  //   }
  // }
  return (
    <div id="MoreInfoModal" className={`${!show ? 'hide' : ''}`}>
      <button onClick={handleClose}><img src={close} alt="" height="15" width="15" /></button>
      <div className="b2b-modal-info">
        <div className="modal-info-item">
          <h3><img src={Balance} alt="" height="18" width="18" />Balance Details </h3>
          {balanceerror && !isLoading && <div className="modal-info-error">{balanceerror}&nbsp;
            <span className='reset-link' onClick={getBalanceDetails}>Retry</span>
          </div>}

          {!balance && isLoading && <div className="modal-info-error">
            <ContentLoader rows={1} area='content-loader-container' />

          </div>}

          {!balanceerror && balance && <>
            {balance.accountBalance && <p><img src={arrow} alt="" width="10" /><b>Balance</b> - ₹ {balance.accountBalance}</p>}
            {/* <p><img src={arrow} alt="" width="10" /><b>Validity</b> - 30 days</p> */}
            {balance.monthlyCharge && <p><img src={arrow} alt="" width="10" /><b>Monthly Charges</b> - ₹ {balance.monthlyCharge}</p>}
            {/* {balance.nextRechargeDate && <p><img src={arrow} alt="" width="10" /><b>Last Recharge Date</b> - {dateFormatter(balance.nextRechargeDate - ThirtyDayMS)}</p>} */}
            {balance.nextRechargeDate && <p><img src={arrow} alt="" width="10" /><b>Next Recharge Date</b> - {dateFormatter(balance.nextRechargeDate)}</p>}
          </>}
        </div>
        <div className="modal-info-item">
          <Link className='link transaction' to={{
            pathname: PATHS.TRANSACTION_HISTORY,
            state: {
              subscriberId: subscriberId,
              noOfRows: connectionLength
            }
          }} >Transaction History</Link></div>
        <div className="modal-info-item">
          <Link className='link service' to={{
            pathname: PATHS.SERVICE_REQUEST,
            state: {
              subId: subscriberId,
              noOfRows: connectionLength
            }
          }} >Raise a Service Request</Link></div>
        {connectionType === 'Primary' && <div className="modal-info-item">
          <span className={`reset-link ${!isHardReset ? 'disable' : ''}`} onClick={isHardReset ? handleHardReset : null}>
            Heavy Refresh, {connectionType}</span>
        </div>}
      </div>

    </div>
  )
}

export default MoreInfoModal
