import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { hideToastInfo } from '../../../../storeManager/common/actions';
var timeout;
const Toast = ({ toastInfoMsg, toastTime }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (toastInfoMsg) {
            timeout = setTimeout(() => {
                dispatch(hideToastInfo())
            }, toastTime + 1000)
        }
        return (() => {
            clearTimeout(timeout)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='toast-info-wrap' style={{ animationDelay: `${toastTime / 1000}s` }}>
            {toastInfoMsg}
            <div className='progress-bar' style={{ animationDuration: `${toastTime / 1000}s` }}></div>
        </div>
    )
}

export default Toast;