import React, {Component, Fragment} from "react";
import {withRouter} from 'react-router-dom';
import {commonService} from "../../../storeManager/common/services";
import GlobalConfig from "../../../globalConfig/globalConfig";
import Loader from "../../Loader";
import {isArray} from "lodash";
import Error from "../../Common/Error";
import {MODALS} from "../../../storeManager/modal/constants";
import {connect} from "react-redux";
import Modal from "../../Common/Modal";
import {bindActionCreators} from "redux";
import {openModal as openModalHandler} from "../../../storeManager/modal/actions";
import {MESSAGES} from "../../../constants";

class RaiseRequest extends Component {
    state = {
        typeRequest: [],
        selectedTypeRequest: '',
        subTypeRequest: [],
        selectedSubTypeRequest: '',
        subTypeSrObj: {},
        description: '',
        isLoading: true,
        error: '',
    };

    backToPreviousPage = () => {
        this.props.history.go(-1);
    };

    componentDidMount() {
        this.populateSRType()
            .catch(() => {
                console.error(MESSAGES.DID_MOUNT_ERROR);
            });
    }

    populateSRType = async () => {
        this.setState({isLoading: true});
        try {
            const response = await commonService.populateSRTypeApi();
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({
                    typeRequest: responseData.data,
                    isLoading: false
                });
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };

    populateSRSubType = async (selectedType) => {
        const payload = {
            "estSrType": selectedType,
            "srRequestType": "SubId",
        };

        try {
            const response = await commonService.populateSRSubTypeApi(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({
                    subTypeRequest: responseData.data,
                });
            } else {
                this.setState({error: responseData.message})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError})
        }
    };

    onChangeSRTypeHandler = (event) => {
        const target = event.target;
        const selectedValue = target.value;
        this.populateSRSubType(selectedValue).catch(() => {
          console.error(MESSAGES.DID_MOUNT_ERROR);
        });
        const name = target.name;

        this.setState({
            [name]: selectedValue,
            error: ""
        });
    };

    serviceRequestHandler = (event) => {
        const target = event.target;
        let selectedValue = target.value;
        const name = target.name;
        if(name === "description" && selectedValue.charAt(0) === " "){
          selectedValue = selectedValue.replace(" ", "")
        }
        this.setState({
            [name]: selectedValue,
            error: ""
        });
    };

    createServiceRequest = async () => {
        this.setState({isLoading: true});
        const {selectedTypeRequest, selectedSubTypeRequest, description, subTypeRequest} = this.state;
        const subTypeSrObj = subTypeRequest[selectedSubTypeRequest];
        const {subId, noOfRows} = this.props;
        const payload = {
            "description": description,
            "estPriority": subTypeSrObj.estPriority,
            "estSeverity": subTypeSrObj.estSeverity,
            "estSrArea": subTypeSrObj.estSrArea,
            "estSrSubArea": subTypeSrObj.estSrSubArea,
            "estSrType": selectedTypeRequest,
            "subscriberId": subId || '',
            noOfRows
        };
        try {
            const response = await commonService.createServiceRequestApi(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({isLoading: false});
                const {openModal} = this.props;
                openModal(MODALS.CONFIRMATION_POPUP,
                    {
                        message: responseData.message || "Service Request raised successfully.",
                        hideCloseButton: true,
                    })
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };

    submitServiceRequest = (event) => {
        event.preventDefault();
        this.createServiceRequest().catch(() => {
          console.error(MESSAGES.DID_MOUNT_ERROR);
        });
    };

    render() {
        const {
            typeRequest,
            selectedTypeRequest,
            selectedSubTypeRequest,
            subTypeRequest,
            description,
            isLoading,
            error,
        } = this.state;
        const {onChangeSRTypeHandler, serviceRequestHandler, submitServiceRequest, backToPreviousPage} = this;
        return (
            <Fragment>
                {
                    isLoading && <Loader/>
                }
                <form className="service-request-form">
                    <div className="row mrl0 mb20">
                        <div className="col-md pl0 pr10">
                            <select
                                name="selectedTypeRequest"
                                className="dropdown-control"
                                value={selectedTypeRequest}
                                onChange={onChangeSRTypeHandler}
                                multiple={false}
                            >
                                <option value="" defaultValue hidden>--Select Type--</option>
                                {
                                    typeRequest && typeRequest.map((type, id) => (
                                            <option key={id}>{type}</option>
                                        )
                                    )
                                }
                            </select>
                        </div>

                        <div className="col-md pr0 pl10">
                            <select
                                name="selectedSubTypeRequest"
                                className="dropdown-control"
                                value={selectedSubTypeRequest}
                                onChange={serviceRequestHandler}
                                multiple={false}
                            >
                                <option value="" defaultValue hidden>
                                    --Select Sub-Type--
                                </option>
                                {
                                    isArray(subTypeRequest) && subTypeRequest.map((subType, index) => (
                                            <option key={index} value={index}>
                                                {subType.estSrArea}
                                            </option>
                                        )
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className="pb60">
                        <textarea name="description" value={description} onChange={serviceRequestHandler}
                                  className="description-textarea"
                                  placeholder="Describe your complaint"/>
                    </div>

                  {
                    error && <Error error={error}/>
                  }

                    <div className="d-flex justify-content-center pb50 mt30">

                        <button className="button mr20" type="button" onClick={backToPreviousPage}>
                            Cancel
                        </button>
                        <button
                            className="button fill"
                            type="submit"
                            onClick={(e) => submitServiceRequest(e)}
                            disabled={(!selectedTypeRequest) || (!selectedSubTypeRequest) || (!description)}
                        >
                            Submit
                        </button>
                    </div>
                </form>
                <Modal/>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(RaiseRequest));