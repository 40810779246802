import React from 'react';
import CustomTable from './../../Common/CustomTable/index';
import TableColumns from '../TableColumns';
import _isEmpty from 'lodash/isEmpty';
import ContentLoader from './../../Common/ContentLoader/index';
import './style.scss';
import CustomButton from '../../Common/Button';
import Loader from '../../Loader';
import ReactLoading from 'react-loading';

const SelectSubId = (props) => {

    const { handleChange,
        selectedStbId,
        handleProceed,
        handleBack,
        loading,
        getBoxIdList,
        boxlistData,
        showMore,
        searchedList } = props;

    if (boxlistData && boxlistData.boxlist.length <= 0)
        return (
            <Loader />
        )
    return (
        <div className='select-sub-id-wrap'>
            {loading && <div className='pt10'>
                <ContentLoader area='content-loader-container' />
            </div>}
            <div className='table-outer-wrap'>
                <CustomTable
                    columns={TableColumns()}
                    handleChange={handleChange}
                    data={searchedList}
                    checkbox={true}
                    selectAll={true}
                    selectedStbId={selectedStbId}
                    valueKey="vcNumber"
                />
            </div>
            <div className='bulkOperationFooter'>
                {/* {selectedStbId.length > 0 && <span className='in-footer box-text small'>Selected Boxes ({selectedStbId.length})</span>} */}
                <div className='footerWrapper'>
                {showMore &&<div className='bulkOperationFooter-loadmore-sec'>
                        <CustomButton
                            classes="primary"
                            config={{ type: 'button', class: 'secondary' }}
                            onClick={getBoxIdList}
                        >
                            {loading ? <ReactLoading type={'bars'} color={'#E10092'} height={22} width={25} /> : `Load More Boxes (${boxlistData && boxlistData.boxlist.length})`}
                        </CustomButton>
                    </div>}
                    <div className='bulkOperationFooter-button-sec'>
                    <CustomButton
                        content="Back"
                        classes="primary"
                        config={{ type: 'button', class: 'secondary' }}
                        onClick={handleBack}
                    />
                    <CustomButton
                        content="Proceed"
                        classes="primary"
                        config={{ type: 'button' }}
                        onClick={handleProceed}
                        disabled={_isEmpty(selectedStbId)}
                    />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectSubId;