import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { closeModal, openModal as openModalHandler } from "../../../../../storeManager/modal/actions";
import { commonService } from "../../../../../storeManager/common/services";
import GlobalConfig from "../../../../../globalConfig/globalConfig";
import Loader from "../../../../Loader";
import Error from "../../../../Common/Error";
import { restrictDecimalInput } from '../../../../../helper';
import { MODALS } from '../../../../../storeManager/modal/constants';
import { getLocalStorage } from './../../../../../services/commonUtils';
import resendOtpImg from '../../../../../assets/images/resend-otp-new.svg'
class OTPModal extends Component {
    state = {
        otp: '',
        error: '',
        errorMsg: {},
        isLoading: false,
        isOtpSending: false,
        resendOtp: true,
        showMsg: false,
        rmn: '',
        chainId: getLocalStorage("chKeyId") || '',
    };

    componentDidMount() {
        this.inputFocus.focus();
        this.startOtpTimer()
    }

    adminInputChangeHandler = (event) => {
        let { name, value } = event.target;
        if (name === 'otp') {
            if (!isNaN(value)) {
                this.setState({
                    [name]: value,
                    errorMsg: {}
                })
            }
        }
    };

    validateOTP = async () => {
        const { otp } = this.state;
        const { modalParameters: { chainId, newPassword } } = this.props;

        const payload = {
            "newPassword": newPassword,
            "otp": otp,
            "chainId": chainId,
        };
        try {
            const response = await commonService.resetUserPassword(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({ showMsg: true, successMsg: responseData.message, isLoading: false })
            } else {
                this.setState({ error: responseData.message, isLoading: false })
            }
        } catch {
            this.setState({ error: GlobalConfig.serverError, isLoading: false })
        }
    };


    generateOtpForRMN = async () => {
        this.setState({ error: '', isOtpSending: true, otp: '' });
        try {
            const response = await commonService.resetUserPasswordOtp();
            const responseData = response.data;
            this.setState({ error: responseData.message, isOtpSending: false })
            this.startOtpTimer()
        } catch {
            this.setState({ error: GlobalConfig.serverError, isOtpSending: false })
        }

    };

    verifyUser = (e) => {
        e.preventDefault();
        const { showEmailOrPhoneField } = this.state;
        this.setState({ isLoading: true });
        if (showEmailOrPhoneField) {
            this.generateOtpForRMN();
        } else {
            this.validateOTP();
        }
    };

    otpGenerate = async (event) => {
        const { chainId } = this.state
        event.preventDefault();
        this.setState({ isOtpSending: true });
        const payload = {
            "generateOTP": {
                "subscriberId": chainId,
            }
        };

        try {
            const response = await commonService.generateOtp(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({ isOtpSending: false, otp: '', responseMsg: '' });
                // this.setState({ otp: '' })
                // this.setState({ responseMsg: '' })
                this.startOtpTimer()
            } else {
                this.setState({ responseMsg: responseData.message, isOtpSending: false })
            }
        } catch {
            this.setState({ error: GlobalConfig.serverError, isOtpSending: false })
        }
    };

    changePasswordSubmit = async (event) => {
        const { otp } = this.state;
        const { modalParameters: { password, newPassword, confirmNewPassword } } = this.props;
        event.preventDefault();
        this.setState({ isLoading: true });
        const payload = {
            password: password,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword,
            otp: otp,
        };

        try {
            const response = await commonService.submitChangePassword(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({ isLoading: false });
                const { openModal } = this.props;
                openModal(MODALS.CONFIRMATION_POPUP,
                    {
                        message: responseData.message || "Change Password Successfully",
                        hideCloseButton: true,
                    })
            } else {
                this.setState({ responseMsg: responseData.message, isLoading: false })
            }
        } catch {
            this.setState({ error: GlobalConfig.serverError, isLoading: false })
        }
    };
    startOtpTimer = () => {
        let timer = 30;
        let Interval;
        const timerElement = document.querySelector('#timer_div');
        const resendElement = document.querySelector('.link-contr');
        const resendTimer = document.querySelector('.res-otp');
        resendElement.style.display = 'none';
        resendTimer.style.display = 'block';
        Interval = setInterval(() => {
            if (timer === 0 && resendElement && resendTimer) {
                clearTimeout(Interval);
                resendElement.style.display = 'block';
                resendTimer.style.display = 'none';
            } else {
                timer = timer < 10 ? `0${timer}` : timer;
                if (timerElement) {
                    resendTimer.style.display = 'block';
                    timerElement.innerHTML = timer;
                }
                timer--;
            }
        }, 1000);
        // clearTimeout(Interval);
    }
    render() {
        const { otp, error, isLoading, showMsg, successMsg, responseMsg, isOtpSending } = this.state;
        const { modalParameters: { message, type, rmn } } = this.props;
        const { adminInputChangeHandler, verifyUser, generateOtpForRMN, otpGenerate, changePasswordSubmit } = this;
        // const OTPLink = (
        //     <div className="text-btn ptb15" onClick={generateOtpForRMN}>
        //       Resend OTP
        //     </div>
        // );
        // const OTPResend = (
        //     <div className="text-btn ptb15" onClick={otpGenerate}>
        //       Resend OTP
        //     </div>
        // );

        if (showMsg) {
            return (<div className="b2b-modal-header center pb20">
                <div className="gradient-text">
                    {successMsg}
                    {rmn}
                </div>
            </div>)
        }

        return (
            <Fragment>
                {
                    isLoading && <Loader />
                }
                <div className="b2b-modal-header center pb10">
                    <div className="gradient-text mT20">
                        <div className='mb20'>
                            {message}
                        </div>
                    </div>
                    <span className="rmn-field">
                        <span className='rmnColor'> Enter OTP sent to </span> {rmn}
                    </span>
                </div>

                <div className="prl50" style={{ textAlign: 'center' }}>
                    <form onSubmit={(e) => verifyUser(e)}>
                        <Fragment>
                            <div className='optmodal-element-change-p'>
                                <div className="form-element">
                                    <input type="text"
                                        className="input-element otp-pwd cng-p"
                                        placeholder="OTP"
                                        maxLength="6"
                                        name="otp"
                                        value={otp}
                                        onChange={adminInputChangeHandler}
                                        ref={(input) => {
                                            this.inputFocus = input;
                                        }}
                                        autoComplete="off"
                                        onKeyPress={restrictDecimalInput}
                                    />
                                </div>
                            </div>
                            {/*type === 'GenarateOtp' ? OTPResend : OTPLink*/}
                            {isOtpSending ? <span className="lds-hourglass "> Sending</span>
                                :
                                <Fragment>
                                    <div className="link-contr text-btn " onClick={type === 'GenarateOtp' ? otpGenerate : generateOtpForRMN}>
                                        <img src={resendOtpImg} alt="call" title="call " style={{ width: '15px', marginRight: '5px' }} />
                                        Resend OTP
                                    </div>
                                    <div className=" res-otp field-info-otp " style={{ display: 'none' }}>
                                        Resend OTP in 00:
                                        <span id="timer_div" />
                                    </div>
                                </Fragment>}
                        </Fragment>
                        <span
                            className={`${!isOtpSending ? 'fadeout' : ''} success-text`}>
                            OTP sent successfully
                        </span>
                        {
                            error && <Error error={error} />
                        }
                        {type === 'GenarateOtp' && <Error error={responseMsg} />}
                        {type === 'GenarateOtp' ? <button
                            type="submit"
                            className="button fill big w-140 mt10 mb20"
                            onClick={(e) => changePasswordSubmit(e)}
                            disabled={(!otp || otp.length < 6)}
                        >
                            Submit
                        </button> :
                            <button
                                type="submit"
                                className="button fill big w-140 mt10 mb20"
                                onClick={(e) => verifyUser(e)}
                                disabled={(!otp || otp.length < 6)}
                            >
                                Submit
                            </button>}

                    </form>
                </div>
            </Fragment >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(OTPModal));
