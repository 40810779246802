import {INPUT_ERROR} from './../../../constants'
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z0-9\S]{8,32}$/;

const validateConfirmPassword = (confirmPassword, password, keyName) => {
  let name = '';
  let message = '';
  let error = false;
  if (!password) {
    return {
      message: INPUT_ERROR.REQUIRED,
      error: true,
    }
  } else if (password.length < 8) {
      message = INPUT_ERROR.PASSWORD;
      error = true;
  } /*else if (password.length >= 8 && password.length <= confirmPassword.length) {
    message = '';
    error = false;
  }*/ else if (password !== confirmPassword) {
      name = keyName;
      message = INPUT_ERROR.CONFIRM_PASSWORD;
      error = true
  } else if (password === confirmPassword) {
    name = keyName;
    message = '';
    error = false
  }
  return { message, error, name };
};

export default {
  validateName: (inputName) => {
    const maxLength = 50;
    let error = '';
    let hasError = false;
    let name = inputName;
    name = name.trim();
    if (!name) {
      error = 'This field is required';
      hasError = true;
    } else if (name.length > maxLength) {
      error = `Enter max ${maxLength} characters`;
      hasError = true;
    } else {
      error = '';
    }

    return { error, hasError };
  },
  validateTextarea: (inputTextarea) => {
    let error = '';
    let hasError = false;
    const maxLength = 500;
    let textarea = inputTextarea;
    textarea = textarea.trim();
    if (!textarea) {
      error = 'This field is required';
      hasError = true;
    } else if (textarea.length > maxLength) {
      error = `Enter max ${maxLength} characters`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },

  validateEmail: (emailId) => {
    const maxLength = 50;
    let message = '';
    let error = false;
    const regexEmail = /^\w+([-.]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
    if (!emailId) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (!regexEmail.test(String(emailId).toLowerCase())) {
      message = INPUT_ERROR.EMAIL;
      error = true;
    } else if (emailId.length > maxLength) {
      message = `Maximum Length should be ${maxLength}`;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validatePhoneNumber: (phoneNumber) => {
    let message = '';
    let error = false;
    if (!phoneNumber) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (!/^(?:[6-9]|0[1-9]|10)\d{9}$/.test(phoneNumber)) {
      message = INPUT_ERROR.MOBILE_NUMBER;
      error = true;
    } 
    return { message, error };
  },

  validateChainId: (chainId) => {
    let message = '';
    let error = false;
    if (!chainId) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (!/^(?:[0-9]|0[1-9]|10)\d{9}$/.test(chainId)) {
      message = INPUT_ERROR.CHAIN_ID;
      error = true;
    }
    return { message, error };
  },

  validatePassword: (password, confirmPassword, keyName) => {
    let message = '';
    let error = false;
    if(confirmPassword){
      return validateConfirmPassword(confirmPassword, password, keyName)
    } else {
      if (!password) {
        return {
          message: INPUT_ERROR.REQUIRED,
          error: true,
        }
      } else if (password.length < 8) {
        return {
          message: INPUT_ERROR.PASSWORD,
          error: true,
        }
      } else if (!passwordRegex.test(password)) {
        return {
          message: INPUT_ERROR.PATTERN_PASSWORD,
          error: true,
        }
      } else return { message, error };
    }
  },

  validateConfirmPassword: (confirmPassword, password) => {
    let message = '';
    let error = false;
    if (!confirmPassword) {
      return {
        message: INPUT_ERROR.REQUIRED,
        error: true
      }
    } else if (password !== confirmPassword) {
      return {
        message: INPUT_ERROR.CONFIRM_PASSWORD,
        error: true
      }
    } else return { message, error };
  },

  validateSubId: (subId) => {
    const maxLength = 10;
    let error = '';
    let hasError = false;
    if (!subId) {
      error = 'This field is required';
      hasError = true;
    } else if (!/^[0-9]+$/.test(subId)) {
      error = 'Not a valid subId';
      hasError = true;
    } else if (subId.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (subId.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }

    return { error, hasError };
  },
  validateLoginOtp: (otp) => {
    const maxLength = 4;
    let error = '';
    let hasError = false;
    if (!otp) {
      error = 'This field is required';
      hasError = true;
    } else if (!/^[0-9]+$/.test(otp)) {
      error = 'Not a valid otp';
      hasError = true;
    } else if (otp.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (otp.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateRegistrationOtp: (otp) => {
    const maxLength = 6;
    const minLength = 6;
    let error = '';
    let hasError = false;
    if (!otp) {
      error = 'This field is required';
      hasError = true;
    } else if (!/^[0-9]+$/.test(otp)) {
      error = 'Not a valid Otp';
      hasError = true;
    } else if (otp.length < minLength) {
      error = `Minimum Length should be ${minLength}`;
      hasError = true;
    } else if (otp.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateAdhaar: (inputValue) => {
    const maxLength = 12;
    let error = '';
    let hasError = false;
    if (!inputValue) {
      error = 'This field is required';
      hasError = true;
    } /*else if (!/^\\d{12}$/.test(inputValue)) {
      error = 'Not a valid adhaar number';
      hasError = true;
    }*/ else if (inputValue.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (inputValue.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validatePanCard: (inputValue) => {
    const maxLength = 10;
    let error = '';
    let hasError = false;
    if (!inputValue) {
      error = 'This field is required';
      hasError = true;
    } else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(inputValue)) {
      error = 'Not a valid Pancard Format';
      hasError = true;
    } else if (inputValue.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (inputValue.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateVoterId: (inputValue) => {
    const maxLength = 11;
    let error = '';
    let hasError = false;
    if (!inputValue) {
      error = 'This field is required';
      hasError = true;
    } else if (!/[a-zA-Z0-9]{10,11}/.test(inputValue)) {
      error = 'Not a valid voterId';
      hasError = true;
    } else if (inputValue.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (inputValue.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateDrivingLicense: (inputValue) => {
    const maxLength = 16;
    let error = '';
    let hasError = false;
    if (!inputValue) {
      error = 'This field is required';
      hasError = true;
    } else if (!/^([a-zA-Z]){2}.{14}?$/.test(inputValue)) {
      error = 'Not a valid Driving License';
      hasError = true;
    } else if (inputValue.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (inputValue.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validatePassport: (inputValue) => {
    const maxLength = 9;
    const minLength = 8;
    let error = '';
    let hasError = false;
    if (!inputValue) {
      error = 'This field is required';
      hasError = true;
    } else if (!/[a-zA-Z0-9]{8,9}/.test(inputValue)) {
      error = 'Not a valid passport';
      hasError = true;
    } else if (inputValue.length < minLength) {
      error = `Minimum Length should be ${minLength}`;
      hasError = true;
    } else if (inputValue.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateAmount: (amount) => {
    // const maxLength = 6;
    let error = '';
    let hasError = false;
    if (!amount) {
      error = 'This field is required';
      hasError = true;
    } else if (!/^[0-9]+$/.test(amount) || amount.charAt(0) === '0') {
      error = 'Not a valid Amount ';
      hasError = true;
    } /*else if (amount.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (amount.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    }*/ else {
      error = '';
    }
    return { error, hasError };
  },
  validateServiceType: (selectType) => {
    let error = '';
    let hasError = false;
    let connectionFor = selectType;
    connectionFor = connectionFor.toString().trim();
    if (!connectionFor || selectType === "true") {
      error = 'This field is required';
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validatePinCode: (pinCode) => {
    const maxLength = 6;
    let error = '';
    let hasError = false;
    if (!pinCode) {
      error = 'This field is required';
      hasError = true;
    } else if (!/^[0-9]+$/.test(pinCode) || pinCode.charAt(0) === '0') {
      error = 'Not a valid pin code';
      hasError = true;
    } else if (pinCode.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (pinCode.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateAddress: (inputAddress) => {
    const maxLength = 72;
    // const minLength = 5;

    let error = '';
    let hasError = false;
    let address = inputAddress;
    address = address.trim();
    if (!address) {
      error = 'This field is required';
      hasError = true;
    } else if (address.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }

    return { error, hasError };
  },
  validateLandmark: (inputLandmark) => {
    const maxLength = 72;
    let error = '';
    let hasError = false;
    let landmark = inputLandmark;
    landmark = landmark.trim();
    if (!landmark) {
      error = 'This field is required';
      hasError = true;
    } else if (landmark.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateByArray: (inputValue, validationArray) => {
    let error = '';
    let hasError = false;
    let value = inputValue;
    value = value.toString().trim();
    if (Array.isArray(validationArray) && validationArray.length > 0) {
      for (let i = 0; i < validationArray.length; i += 1) {
        const currentValidation = validationArray[ i ];
        if (currentValidation.validation === 'required') {
          if (!value) {
            error = 'This field is required';
            hasError = true;
            break;
          }
        }
      }
    }
    return { error, hasError };
  },

  validatePasswordCheck: (password) => {
    let message = '';
    let error = false;

    if (!password) {
      return {
        message: INPUT_ERROR.REQUIRED,
        error: true,
      }
    } else if (password.length < 8) {
      return {
        message: INPUT_ERROR.PASSWORD,
        error: true,
      }
    } else if (!passwordRegex.test(password)) {
      return {
        message: INPUT_ERROR.PATTERN_PASSWORD,
        error: true,
      }
    } else return { message, error };

  },
  validateConfirmPasswordCheck: (password, confirmPassword) => {
    let message = '';
    let error = false;

    if (!password) {
      return {
        message: INPUT_ERROR.REQUIRED,
        error: true,
      }
    } else if (password.length < 8) {
      return {
        message: INPUT_ERROR.PASSWORD,
        error: true,
      }
    } else if (!passwordRegex.test(password)) {
      return {
        message: INPUT_ERROR.PATTERN_PASSWORD,
        error: true,
      }
    } else if (confirmPassword && confirmPassword !== password) {
      return {
        message: INPUT_ERROR.CONFIRM_PASSWORD,
        error: true,
      }
    } else return { message, error };

  },
  /*validateGST: (inputGSTNo) => {
    const maxLength = 15;
    let error = '';
    let hasError = false;
    let gstNo = inputGSTNo;
    gstNo = gstNo.toString().trim();
    const regexGst = getExpression('gstNo');
    if (gstNo) {
      if (!regexGst.test(String(gstNo).toLowerCase())) {
        error = 'Not a valid GST Number';
        hasError = true;
      } else if (gstNo.length > maxLength) {
        error = `Enter max ${maxLength} characters`;
        hasError = true;
      }
    } else {
      error = '';
    }
    return { error, hasError };
  },*/
};
