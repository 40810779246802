import React from 'react';
import { useSelector } from 'react-redux';
import './style.scss';
import Toast from './Toast';

const ToastInfo = (props) => {
    const { tostInfoMsg, toastTime } = useSelector(state => state.commonReducer)
    if (tostInfoMsg) {
        return (
            <Toast toastInfoMsg={tostInfoMsg} toastTime={toastTime} />
        )
    }
    else {
        return null
    }
}

export default ToastInfo;
