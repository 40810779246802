import React, { useState } from "react";
import update from '../../../assets/images/update.svg';
import editImg from '../../../assets/images/edit_pencil-d.svg';
import { commonService } from "../../../storeManager/common/services";
import Error from "../../Common/Error";
import GlobalConfig from '../../../globalConfig/globalConfig';

const EditableName = (props) => {

    const { cell, row, BoxNameUpdateInTable } = props;

    const [isEdit, setIsEdit] = useState(false);
    const [name, setName] = useState(cell);
    const [isLoader, setIsLoader] = useState(false);
    const [error, setError] = useState(null);

    const onEdit = () => {
        setIsEdit(!isEdit);
    }
    // Api calling for submitting Bulk modification
    const changeNameHandler = async () => {
        setIsEdit(false);
        const payload = {
            "vcNumber": row.vcNumber,
            "newBoxName": name,
            "subscriberId": row.subscriberId,
        }
        setIsLoader(true)
        try {
            const response = await commonService.updateBoxName(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                setIsLoader(false)
                setError('')
                BoxNameUpdateInTable(payload)
            } else {
                setError(responseData.message)
                setName(cell);
                setIsLoader(false)
            }
        } catch {
            setError(GlobalConfig.serverError)
            setName(cell);
            setIsLoader(false)
            
        }
    }
    if (isLoader) 
    return ('Loading...')
    return (
        <div style={{whiteSpace:'nowrap'}}>
            {isEdit ?
                <>
                    <input className='inputval' name='BoxFriendly' onChange={(e) => setName(e.target.value)} value={name} />
                    <button className='a-link' onClick={(e) => { changeNameHandler(e) }}>
                        <img src={update} alt="" height="15" width="15" />
                    </button>
                </>
                :
                <>
                    <span title={name} className={`spanval ${name==='-' && 'center'}`}>{name} </span>
                    <button className='a-link' onClick={(e) => { onEdit(e) }}>
                        <img src={editImg} alt="" height="13" width="13" />
            </button>
                </>
            }
            {error && <Error error={error} />}
        </div>
    )
}

export default EditableName;