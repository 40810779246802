import React,{useState} from 'react';
import MoreInfoModal from '../../Dashboard/Modal/moreInfoModal';
import eyeImg from '../../../assets/images/view.svg'

const ShowMoreDropbox = ({ row, heavyRefresh }) => {

    const [isShow, setIsShow] = useState(false);

    return(
        <>  
            <div className={isShow ? 'background-overlay' : ''} onClick={() => setIsShow(false)}/>
            <button className='a-link' onClick={() => setIsShow(true)}><img src={eyeImg} alt="" height="15" width="15" /> </button>
            {isShow ?
                <MoreInfoModal
                    show={isShow}
                    handleClose={() => setIsShow(false)}
                    subscriberId={row.subscriberId}
                    hardReset={row.hardReset}
                    connectionType={row.connectionType}
                    heavyRefresh={heavyRefresh}
                     /> : null}
        </>
    )
}

export default ShowMoreDropbox;