import React from 'react';
import BulkPackModify from '../BulkPackModify';
import BulkStatusUpdate from '../BulkStatusUpdate';
import './bulk-modify.scss';

const BulkModifyRevamp = (props) => {
  const { history: { location } } = props;

  return (
    <div className="b2b-bulk-modify-revamp">

      <div className="content-wrapper pb60">
        <div className="d-flex justify-content-between mb20">
          <h2 className="heading">
            {location.pathname === '/bulk-modify' ? "Bulk Pack Modify" : "Bulk Status Update"}
          </h2>
        </div>
        {/* <div className="prev-page-link pb20" onClick={() => handleProceedBtn("back")}>
          <i className="back-link">
            <svg width="25" height="16">
              <path fill="currentColor" fillRule="evenodd"
                d="M8.336.599a.923.923 0 0 1 1.211 0 .703.703 0 0 1 0 1.067L3.406 7.131h20.265c.473 0 .86.334.86.755 0 .42-.387.765-.86.765H3.406l6.141 5.456a.714.714 0 0 1 0 1.077.923.923 0 0 1-1.211 0L.741 8.425a.703.703 0 0 1 0-1.067L8.336.599z" />
            </svg>
          </i>
          Back
    </div>*/}

        <div className="bulk-operation-tabs d-flex justify-content-center ">
          {/* <div className='tab-wrapper d-flex align-items-center justify-content-center '>
            <div
              className={`tab big no-radius d-flex align-items-center justify-content-center ${tabIndex === 1 ? 'btn-fill' : ''}`}
              onClick={() => setTabIndex(1)}
            >
              Bulk Pack Modify
            </div>
            <div
              className={`tab big no-radius d-flex align-items-center justify-content-center ${tabIndex === 2 ? 'btn-fill' : ''}`}
              onClick={() => setTabIndex(2)}
            >
              Bulk Status Update
            </div>
          </div> */}

        </div>
        {location.pathname === '/bulk-modify' ? <BulkPackModify /> : <BulkStatusUpdate />}
        {/* {tabIndex === 1 && <BulkPackModify />}
        {tabIndex === 2 && <BulkStatusUpdate />} */}
      </div>
    </div>
  )
}

export default BulkModifyRevamp;
