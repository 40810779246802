import React, {Component, Fragment} from 'react';
import {MESSAGES, PATHS} from "../../constants";
import './temporarily-suspend.scss';
import PrimaryConnection from "./PrimaryConnection";
import SecondaryConnection from "./SecondaryConnections";
import GlobalConfig from "../../globalConfig/globalConfig";
import {commonService} from "../../storeManager/common/services";
import Error from "../Common/Error";
import Loader from "../Loader";
import {getCategoryWiseData, getDateFormatYYYYMMDD} from "../../helper";
import {MODALS} from "../../storeManager/modal/constants";
import Modal from "../Common/Modal";
import {openModal as openModalHandler} from "../../storeManager/modal/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: bindActionCreators(openModalHandler, dispatch),
  }
};

class TemporarilySuspend extends Component {
  state = {
    error: '',
    isLoading: true,
    dataListObj: {},
    subId: "",
    selected: null,
    noOfRows: null,
  };

  backToDashboard = () => {
    this.props.history.push(PATHS.HOME);
  };

  componentDidMount() {
    const { history: {location: { state }}, history } = this.props;
    if (state && state.subscriberId) {
      this.getSuspendDetail(state.subscriberId, state.noOfRows)
          .catch(() => {
            console.error(MESSAGES.DID_MOUNT_ERROR);
          });
      this.setState({subId: state.subscriberId, noOfRows: state.noOfRows})
    } else history.push(PATHS.HOME)
    this.setState({selected:state.vcNumber})
  }

  getSuspendDetail = async (subId, noOfRows) => {
    const { openModal } = this.props;
    const payload = {
      "subscriberId": subId,
      noOfRows
    };
    try {
      const response = await commonService.suspendStatusApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        const categoryWiseData = getCategoryWiseData(responseData.data, "assetType");
        this.setState({ isLoading: false, dataListObj: categoryWiseData });
      } else {
        openModal(MODALS.CONFIRMATION_POPUP, {
          message: responseData.message,
          hideCloseButton: true,
        });
        this.setState({ isLoading: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false })
    }
  };

  getSuspendResumeDetail = (obj, endDate, startDate, vcStatus) => async () => {
    this.setState({ isLoading: true });
    const { noOfRows } = this.state;
    const payload = {
      "actionType": vcStatus,
      "resumeDate": `${getDateFormatYYYYMMDD(endDate)}`,
      "stbNumber": obj.stbNumber,
      "stbType": obj.assetType,
      "subscriberId": this.state.subId,
      "suspDate": vcStatus === "Suspend" ? `${getDateFormatYYYYMMDD(startDate)}` : "",
      noOfRows
    };

    try {
      const { openModal } = this.props;
      const response = await commonService.suspendResumeStatusApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        this.setState({ isLoading: false });
        openModal(MODALS.CONFIRMATION_POPUP, {message: responseData.message, hideCloseButton: true, reDirectLinkHide: true, showOkCloseButton: true})
      } else {
        this.setState({ isLoading: false });
        openModal(MODALS.CONFIRMATION_POPUP, {message: responseData.message, reDirectLinkHide: true, showOkCloseButton: true})
        // this.setState({ error: responseData.message, isLoading: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false })
    }
  };

  radioButtonChangeHandler = (key) => {
    this.setState({ selected: key })
  };

  render() {
    const { backToDashboard, getSuspendResumeDetail, radioButtonChangeHandler } = this;
    const { error, isLoading, dataListObj, selected } = this.state;
    return (
      <div className="temporarily-suspend">
        {
          isLoading && <Loader/>
        }
        <div className="content-wrapper">
          <div className="prev-page-link" onClick={backToDashboard}>
            <i className="back-link">
              <svg width="25" height="16">
                <path fill="currentColor" fillRule="evenodd"
                      d="M8.336.599a.923.923 0 0 1 1.211 0 .703.703 0 0 1 0 1.067L3.406 7.131h20.265c.473 0 .86.334.86.755 0 .42-.387.765-.86.765H3.406l6.141 5.456a.714.714 0 0 1 0 1.077.923.923 0 0 1-1.211 0L.741 8.425a.703.703 0 0 1 0-1.067L8.336.599z"/>
              </svg>
            </i>
            Back to Connection List
          </div>
          {
            error ? <Error error={error}/> : (
              <Fragment>
                <div className="d-flex justify-content-center ptb20">
                  <h2 className="heading text-center">
                    Which TV do your want to temporarily suspend?
                  </h2>
                </div>
                <div className="suspend-form-container">
                  <PrimaryConnection
                    data={dataListObj.Primary ? dataListObj.Primary[0] : null}
                    getSuspendResumeDetail={getSuspendResumeDetail}
                    radioButtonChangeHandler={radioButtonChangeHandler}
                    checked={selected}
                  />
                  {
                    dataListObj.Secondary ? (
                      <SecondaryConnection
                        data={dataListObj.Secondary}
                        getSuspendResumeDetail={getSuspendResumeDetail}
                        radioButtonChangeHandler={radioButtonChangeHandler}
                        checked={selected}
                        primarySuspended={dataListObj.Primary ? dataListObj.Primary[0].vcStatus : null}
                      />
                    ) : null
                  }
                </div>
              </Fragment>
            )
          }
        </div>
        <Modal />
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(TemporarilySuspend);
