import React, { useState } from 'react'
import CustomButton from '../../../Common/Button';
import PackCard from '../../../Common/PackCard';
// import Loader from '../../../Loader';
import ReactLoading from 'react-loading';

const PackGroup = (props) => {
    const { data, onChange, selectedPack } = props;
    const [maxCount, setMaxCount] = useState(12);
    const [isLoading, setIsLoading] = useState(false);
    const handleBtn = () => {
        setIsLoading(true)
        setTimeout(() => {
            setMaxCount(maxCount + 8)
            setIsLoading(false)
            window.scrollTo({ top: document.querySelector('.collapse.show').clientHeight, behavior: 'smooth' })
        },400)
    }
    const mapData = () => {
        const finalData = [];
        for (let i = 0; i < maxCount && i < data.length; i++) {
            finalData.push(
                <PackCard
                    cardData={data[i]}
                    key={`${i}-${data[i].title}`}
                    onChange={onChange}
                    name="broadCasterPack"
                    type="radio"
                    value={JSON.stringify({ name: data[i].pack_name, amount: data[i].pack_mrp })}
                    id={data[i].package_id}
                    selectedPack={selectedPack}
                />
            )
        }
        return finalData.length > 0 ? finalData : 'Sorry, No Data Found';
    }
    return (
        <div className='tp-packs'>
            <div className="four-column packs">
                {
                    mapData()
                }

            </div>
            <div className='text-center broadcaster-footer'>
                <div className='bulkOperationFooter-text'>
                    Showing <b>{maxCount > data.length ? data.length : maxCount}</b> / <b>{data.length}</b>
                </div>
                {maxCount < data.length && <CustomButton
                    onClick={handleBtn}
                    // content="Load More"
                    classes="primary"
                    config={{
                        type: 'button',
                        class: 'secondary'
                    }}
                >
                    {isLoading ? <ReactLoading type={'bars'} color={'#E10092'} height={22} width={25} /> : 'Load More'}
                </CustomButton>
                }
                
            </div>

        </div>
    )
}

export default PackGroup;