import {ACTION} from './constants';

const initialState = {

};

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.RESET_STATE:
      return {...initialState};
    case ACTION.BULK_OPERATION_SUCCESS:
      return { ...state, bulkOperationData:action.bulkOperationData }  
    case ACTION.SHOW_TOAST_INFO:
      return { ...state, tostInfoMsg: action.data.message, toastTime: action.data.time }
    case ACTION.HIDE_TOAST_INFO:
      return { ...state, tostInfoMsg: null, toastTime:0 }
    default:
      return state;
  }
}