/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom"
import './style.scss';
import GlobalConfig from "../../globalConfig/globalConfig";
import { debounce, isArray, isEmpty } from "lodash";
import { commonService } from "../../storeManager/common/services";
import ToggleSwitch from '../Common/ToggleSwitch';
import AlLaCarte from './AlLaCarte/index';
import TpCorporatePacks from './TpCorporatePacks/index';
import TpPacks from './TpPacks/index';
import BroadcasterPack from './BroadcasterPack';
import { setLocalStorage, setSession } from '../../services/commonUtils';
import { getLocalStorage } from './../../services/commonUtils';
import SearchBar from '../Common/SearchBar';
import ContentLoader from '../Common/ContentLoader';
import Stepper from '../Common/stepper';
import SelectSubId from './SelectSubId';
import Loader from '../Loader';
import Error from '../Common/Error';
import { getSession } from './../../services/commonUtils';
// import { MODALS } from './../../storeManager/modal/constants'
import { API_REQUEST_KEYS } from "./../../constants";
import _isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux'
// import { openModal } from '../../storeManager/modal/actions'
import Modal from '../Common/Modal'
import { hideToastInfo, showToastInfo } from '../../storeManager/common/actions';
import ToastInfo from '../Common/ToastInfo';

const BulkPackModify = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const isDashboard = (history && history.location && history.location.isDashboard) || false;
  const AlLaCarteParam = '{ "languages": [], "genres": [], "hd": "", "bouquet": ["Channel"] }';
  const TPParam = '{"languages":[ ],"genres":[ ],"hd":"", "bouquet":["TataSky","Tata Sky OTT Combo"]} ';
  const broadcasterParam = '{"languages":[ ],"genres":[ ],"hd":"", "bouquet":["BroadCaster"]}';

  const [tabIndex, setTabIndex] = useState('al_la_carte')
  const [state, setState] = useState({ isLoading: false });
  const [genreList, setGenreList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [tabFilteredData, setTabFilteredData] = useState([]);
  const [tabSearchedData, setTabSearchedData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [filter, setFilter] = useState({ isHd: false, lang: '', genre: '' });
  const [selectedPack, setSelectedPack] = useState(null);
  const [activeStep, SetactiveStep] = useState(1);
  const [selectedStbId, setSelectedStbId] = useState([]);
  const [selectedSubId, setSelectedSubId] = useState(isDashboard ? getSession('sid') : []);
  const [isLoader, setIsLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [pageCount, setpageCount] = useState('');
  const [boxlistData, setBoxlistData] = useState({ boxlist: [] });
  const [searchedList, setSearchedList] = useState([]);

  let count = pageCount || 0;
  let updateData = [...boxlistData.boxlist];

  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false)
    }, 500)
    getPack(AlLaCarteParam, 'al_la_carte')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Find time difference
  const getTimeDiff = (time) => {
    const prevTime = new Date(time);
    const currentTime = new Date();
    const Difference_In_Time = currentTime.getTime() - prevTime.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
  }

  // Merge data Arrays
  const mergeData = (data) => {
    let finalData = [];
    isArray(data) && data.forEach(el => {
      finalData = [...finalData, ...el.packages]
    })
    return finalData;
  }

  // Find Filter List
  const getFilterList = (data, type) => {
    const filterList = [];
    isArray(data) && data.forEach(el => {
      if (el[type] !== null) {
        if (filterList.length > 0) {
          isArray(el[type])
            && el[type].length > 0
            && el[type].forEach(item => {
              if (!filterList.includes(item.trim()))
                filterList.push(item)
            })
        }
        else {
          filterList.push(...el[type])
        }
      }
    })
    return filterList.sort();
  }

  // Mapping Pack Data
  const mapPackData = (data, type) => {
    const mergedData = type === 'tp_corporate_pack' ? data : mergeData(data);
    const langFilterList = mergedData.filter((o) => o.hasOwnProperty('language')).length > 0 ? getFilterList(mergedData, 'language') : [];
    const genreFilterList = mergedData.filter((o) => o.hasOwnProperty('genre')).length > 0 ? getFilterList(mergedData, 'genre') : [];
    setTabData(type === 'broadCaster' ? data : mergedData)
    setTabFilteredData(type === 'broadCaster' ? data : mergedData)
    setLangList(langFilterList)
    setGenreList(genreFilterList);
    const localStorageData = {
      data: type === 'broadCaster' ? data : mergedData,
      language: langFilterList,
      genre: genreFilterList,
      timeStamp: new Date()
    }

    localStorageData && localStorageData.data.length > 0 && setLocalStorage(type, localStorageData);
  }

  // Api for Corporate Packs
  const getCorporatePack = async () => {
    const packData = getLocalStorage('tp_corporate_pack') || null;
    setLoading(true)
    try {
      if (!isEmpty(packData) && getTimeDiff(packData.timeStamp) <= 1) {
        setGenreList(packData.genre);
        setLangList(packData.language);
        setTabData(packData.data);
        setTabFilteredData(packData.data);
        setLoading(false)
      }
      else {
        const response = await commonService.getCorporatePacks();
        const responseData = response.data;
        if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
          let data = responseData.data;
          const transformedData = data.map(({ hdCount, packMrp, packName, sdCount, ...rest }) => ({ hd_count: hdCount, pack_mrp: packMrp, pack_name: packName, sd_count: sdCount, ...rest }));
          mapPackData(transformedData, 'tp_corporate_pack')
          setLoading(false)
          setState({ error: null })
        } else {
          mapPackData([], 'tp_corporate_pack')
          setLoading(false);
          setState({ error: responseData.message });
        }
      }
    } catch {
      mapPackData([], 'tp_corporate_pack')
      setLoading(false)
      setState({ error: GlobalConfig.serverError })
    }
  }

  // Api calling for packs (TP pack, Al-La-Carte, Broadcaster)
  const getPack = async (param, bqType) => {
    const packData = getLocalStorage(bqType) || null;
    setLoading(true)
    try {
      if (!isEmpty(packData) && getTimeDiff(packData.timeStamp) <= 1) {
        setGenreList(packData.genre);
        setLangList(packData.language);
        setTabData(packData.data);
        setTabFilteredData(packData.data);
        setLoading(false)
      }
      else {
        const response = await commonService.getPacks(param);
        const responseData = response.data;
        if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
          let data = responseData.data;
          mapPackData(data.packages, bqType);
          setLoading(false)
          setLoading(false)
        } else {
          mapPackData([], bqType);
          setLoading(false)
          setState({ error: responseData.message })
        }
      }

    } catch {
      mapPackData([], bqType);
      setLoading(false)
      setState({ error: GlobalConfig.serverError })
    }
  };

  // Api calling for submitting Bulk modification
  const handleProceed = async () => {
    const payload = {
      "amt": Number(JSON.parse(selectedPack).amount),
      "pack": JSON.parse(selectedPack).name,
      "subIdVc": []
    }
    setIsLoader(true)
    try {
      const mappedList = isArray(selectedSubId) && selectedSubId.map(subId => subId)
      payload.subIdVc = mappedList;
      const response = await commonService.bulkPackModify(payload);
      const responseData = response.data;
      setSession('bulkOpetaionResponse', responseData)
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        // let data = responseData?.data;
        setLoading(false)
        setIsLoader(false)
        sessionStorage.removeItem('sid');
      } else {
        setState({ error: responseData.message })
        setLoading(false)
        setIsLoader(false)
      }
      history.push('/operation-success')
    } catch {
      setState({ error: GlobalConfig.serverError })
      setLoading(false)
      setIsLoader(false)
    }
  }

  // Handling Bouquet Tab (al-la-carte, tp-packs, corporate packs, broadcaster packs)
  const handleBouquetTab = (bqType) => {
    setState({error:null})
    setTabIndex(bqType);
    setSelectedPack(null);
    SetactiveStep(1)
    setSelectedStbId([]);
    !isDashboard && setSelectedSubId([]);

    // eslint-disable-next-line default-case
    setFilter({ isHd: false, lang: '', genre: '' });
    switch (bqType) {
      case 'al_la_carte':
        getPack(AlLaCarteParam, bqType)
        break;
      case 'tp_corporate_pack':
        getCorporatePack();
        break;
      case 'tp_pack':
        getPack(TPParam, bqType)
        break;
      case 'broadCaster':
        getPack(broadcasterParam, bqType)
        break;
    }
  }

  // Filter for HD packs
  const getFilteredHDPack = (el) => {
    const filteredArr = []
    el.length > 0 && el.forEach(e => {
      if (e.hd_count > 0)
        filteredArr.push(e);
    })
    return filteredArr;
  }

  // Filter and Search handling
  const handleFilter = (e, type) => {
    setLoading(true)
    let finalResult = [];
    const currentFilter = filter;
    switch (type) {
      case 'HD':
        currentFilter.isHd = e.target.checked;
        break;
      case 'language':
        currentFilter.lang = e.target.value;
        break;
      case 'genre':
        currentFilter.genre = e.target.value;
        break;
    }
    setFilter(currentFilter);
    if (currentFilter.isHd) {
      if (tabIndex === 'broadCaster') {
        tabData.length > 0 && tabData.forEach(el => {
          const innerObj = {
            name: el.name,
            packages: getFilteredHDPack(el.packages)
          }
          finalResult.push(innerObj)
        })
      } else {
        tabData.length > 0 && tabData.forEach(el => {
          if (el.hd_count > 0)
            finalResult.push(el);
        })
      }
    }
    else {
      finalResult = tabData;
    }
    if (!isEmpty(currentFilter.lang)) {
      const unfilteredData = [...finalResult];
      if (tabIndex === 'broadCaster') {
        const broadCasterResult = [];
        unfilteredData.length > 0 && unfilteredData.forEach(item => {
          const innerObj = {
            name: item.name,
            packages: [...item.packages.filter(el => el.language.map(e => e.toLowerCase().trim()).includes(currentFilter.lang.toLowerCase().trim()))]
          }
          broadCasterResult.push(innerObj)
        })
        finalResult = [...broadCasterResult];
      } else {
        finalResult = [...unfilteredData.filter(el => el.language.map(e => e.toLowerCase().trim()).includes(currentFilter.lang.toLowerCase().trim()))]
      }
    }
    if (!isEmpty(currentFilter.genre)) {
      const unfilteredData = [...finalResult];
      if (tabIndex === 'broadCaster') {
        const broadCasterResult = [];
        unfilteredData.length > 0 && unfilteredData.forEach(item => {
          const innerObj = {
            name: item.name,
            packages: [...item.packages.filter(el => el.genre.map(e => e.toLowerCase().trim()).includes(currentFilter.genre.toLowerCase().trim()))]
          }
          broadCasterResult.push(innerObj)
        })
        finalResult = [...broadCasterResult];
      } else {
        finalResult = [...unfilteredData.filter(el => el.genre.map(e => e.toLowerCase().trim()).includes(currentFilter.genre.toLowerCase().trim()))]
      }
    }
    if (searchInput.length > 0) {
      // const unfilteredData = [...finalResult];
      const searchResult = doSearch(searchInput, finalResult, true)
      setTabSearchedData(searchResult)
    }
    setTabFilteredData(finalResult)
    return Promise.resolve();
  }

  // loader for filtering Process
  const handleFilterWithLoader = (e, type) => {
    handleFilter(e, type).then(() => { setLoading(false) })
  }

  // Search Operation
  const doSearch = (input, data = [], filterFlag = false) => {
    const rawData = filterFlag ? data : tabFilteredData;
    let resultData = [];
    if (tabIndex === 'broadCaster') {
      const broadCasterResult = [];
      rawData.length > 0 && rawData.forEach(item => {
        const innerObj = {
          name: item.name,
          packages: [...item.packages.filter(e => e.pack_name.toLowerCase().trim().includes(input))]
        }
        broadCasterResult.push(innerObj)
      })
      resultData = [...broadCasterResult];
    } else {
      resultData = [...rawData.filter(e => e.pack_name.toLowerCase().trim().includes(input))]
    }
    return resultData;
  }

  // Handle search with debounce
  const handleSearch = debounce((input) => {
    if (activeStep === 1) {
      setSearchInput(input.toLowerCase().trim())
      const resultData = doSearch(input.toLowerCase().trim());
      // setTabFilteredData(resultData);
      setTabSearchedData(resultData)
    } else {
      if (!_isEmpty(input) && input.length > 2) {
        boxlistData && setSearchedList(boxlistData.boxlist.filter(o => o.subscriberId.includes(input)))
      }
      else
        boxlistData && setSearchedList(boxlistData.boxlist)
    }
  }, 500);

  // set selected pack
  const selectPackHandler = (e, value) => {
    setSelectedPack(value)
  }

  // handle next button for next step
  const handleNext = () => {
    setSearchInput('')
    if (!isEmpty(selectedPack))
      SetactiveStep(2)
    if (boxlistData && boxlistData.boxlist.length <= 0)
      getBoxIdList();
  }

  // handle for Sub ids selection 
  const handleSelectSubId = (e, value, isAllSelected = false) => {
    let updatedStbId = [...selectedStbId];
    let updatedSubId = [...selectedSubId];
    dispatch(hideToastInfo())
    if (isAllSelected) {
      const stbIdList = [];
      const subIdList = [];
     
      if (value.length > 250) {
        value.slice(0, 250).forEach(el => {
          stbIdList.push(el.vcNumber)
          subIdList.push(`${el.subscriberId}|${el.vcNumber}`)

        })
        dispatch(showToastInfo("First 250 records have been selected, You can not select more than 250 records", 5000))
      } else {
        value.forEach(el => {
          stbIdList.push(el.vcNumber)
          subIdList.push(`${el.subscriberId}|${el.vcNumber}`)

        })
      }
      setSelectedStbId(stbIdList)
      setSelectedSubId(subIdList)
    }
    else {
      if (e.target.checked) {
        
        if (updatedStbId.length < 250) {
          updatedStbId.push(value.vcNumber);
          updatedSubId.push(`${value.subscriberId}|${value.vcNumber}`);
        } else {
          dispatch(showToastInfo("You can not select more than 250 records", 5000))
        }
      }
      else {
        updatedStbId = updatedStbId.filter(o => o !== value.vcNumber);
        updatedSubId = updatedSubId.filter(o => o !== `${value.subscriberId}|${value.vcNumber}`);
      }
      setSelectedStbId(updatedStbId)
      setSelectedSubId(updatedSubId)
    }

  }

  //API call for Box Id List 
  const getBoxIdList = async (chainId) => {
    setLoading(true)
    const payload = {
      "accountDetails": {
        "noOfRows": 200,
        "page": count,
        "requestType": API_REQUEST_KEYS.requestType.getBoxList,
        "viewRequestType": "DASHBOARD"
      }
    };
    try {
      const response = await commonService.subscriberListApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        let data = responseData.data;
        if (Array.isArray(data.subscriberList)) {
          updateData = updateData.concat(data.subscriberList);
          setBoxlistData({
            boxlist: updateData,
            noDataFound: data.noDataFound,
            isLoading: false,
            hasMoreItems: data.hasMoreItems,
            loadMoreLoader: false,
          })
          setSearchedList(updateData)
          if (data.hasMoreItems && count < 4 && !showMore) {
            count = count + 1
            getBoxIdList()
          } else if (!data.hasMoreItems) {
            setShowMore(false)
            setLoading(false)
          } else {
            setpageCount(count + 1)
            setShowMore(true)
            setLoading(false)
          }
        } else if (isEmpty(data.subscriberList) || !data.hasMoreItems) {
          setpageCount(0)
          setShowMore(false)
          setLoading(false)
        }
        if (count > 4)
          window.scrollTo({ top: document.body.scrollHeight - 800, behavior: 'smooth' })
      } else {
        setLoading(false)
        // this.setState({ error: responseData.message, isLoading: false, hasMoreItems: false, loadMoreLoader: false })
      }
    } catch {
      setLoading(false)
      // this.setState({ error: GlobalConfig.serverError, isLoading: false, hasMoreItems: false, loadMoreLoader: false })
    }
  };
  if (isLoader)
    return (<Loader />)
  return (
    <div className="b2b-bulk-pack-modify ">
      {!isDashboard && <Stepper stepOne="Select the pack" stepTwo="Select Sub Ids which you need to modify" activeStep={activeStep} />}
      {activeStep === 1 && <div className='sub-tab-wrapper'>
        <ul className='d-flex'>
          <li className={`medium-strong ${tabIndex === 'al_la_carte' ? 'active' : ''}`} onClick={() => handleBouquetTab('al_la_carte')}>Al-La-Carte</li>
          <li className={`medium-strong ${tabIndex === 'tp_corporate_pack' ? 'active' : ''}`} onClick={() => handleBouquetTab('tp_corporate_pack')}>Tataplay Corporate Packs</li>
          <li className={`medium-strong ${tabIndex === 'tp_pack' ? 'active' : ''}`} onClick={() => handleBouquetTab('tp_pack')}>Tataplay Packs</li>
          <li className={`medium-strong ${tabIndex === 'broadCaster' ? 'active' : ''}`} onClick={() => handleBouquetTab('broadCaster')}>Broadcaster Packs</li>
        </ul>
      </div>}
      <div className={`top-area ${activeStep === 2 ? 'second-step' : ''}`}>

        {activeStep === 2 && <h2 className='box-text'>Selected Boxes ({selectedStbId.length} / {searchedList.length})</h2>}
        <fieldset className='search-filter-wrap d-flex justify-content-between ' disabled={loading}>
          <SearchBar placeholder={activeStep === 1 ? 'Search by Pack Name' : 'Subscriber ID'} onChange={handleSearch} value={searchInput} />
          {activeStep === 1 && <div className='filter-wrap d-flex align-items-center'>
            <div className='filter-label'>
              Filter:
            </div>
            <div className='hd-wrap d-flex'>
              <span className='hd'>HD</span>
              <ToggleSwitch onChange={(e) => handleFilterWithLoader(e, "HD")} checked={filter.isHd} />
            </div>

            {langList && langList.length > 0 && <select
              name="language"
              className="dropdown-control"
              value={filter.lang}
              onChange={(e) => handleFilterWithLoader(e, "language")}
              multiple={false}
            >
              <option value="" defaultValue hidden>Language</option>
              {isArray(langList) && langList.length > 0 && langList.map((el, ind) => (
                <option key={`${el}-${ind}`} value={el}>{el}</option>
              ))}
            </select>}
            {genreList && genreList.length > 0 && <select
              name="genre"
              className="dropdown-control custom-select"
              value={filter.genre}
              multiple={false}
              onChange={(e) => handleFilterWithLoader(e, "genre")}
            >
              <option value="" defaultValue hidden>Genre</option>
              {isArray(genreList) && genreList.length > 0 && genreList.map((el, ind) => (
                <option key={`${el}-${ind}`} value={el}>{el}</option>
              ))}

            </select>}
          </div>}
        </fieldset>
        {state && loading && activeStep === 1 && <div className='mt10'>

          {state && loading && activeStep === 1 && <ContentLoader area='content-loader-container' />}
        </div>
        }
      </div>
      {/*!isDashboard && !loading && !isLoader && <Stepper stepOne="Select the pack" stepTwo="Select Sub Ids which you need to modify" activeStep={activeStep} />*/}
      <div className='tab-content-wrap'>
        {state.error && <div className='pt10 pb10'>
          <Error error={state.error} />
        </div>
        }
        {tabIndex === 'al_la_carte'
          && activeStep === 1
          && <AlLaCarte
            selectedPack={selectedPack}
            onChange={selectPackHandler}
            data={searchInput.length > 2 ? tabSearchedData : tabFilteredData}
            handleNext={handleNext}
            isDashboard={isDashboard}
            handleProceed={handleProceed}
          />}
        {tabIndex === 'tp_corporate_pack'
          && activeStep === 1
          && <TpCorporatePacks
            selectedPack={selectedPack}
            onChange={selectPackHandler}
            data={searchInput.length > 2 ? tabSearchedData : tabFilteredData}
            handleNext={handleNext}
            isDashboard={isDashboard}
            handleProceed={handleProceed}
          />}
        {tabIndex === 'tp_pack'
          && activeStep === 1
          && <TpPacks selectedPack={selectedPack}
            onChange={selectPackHandler}
            data={searchInput.length > 2 ? tabSearchedData : tabFilteredData}
            handleNext={handleNext}
            isDashboard={isDashboard}
            handleProceed={handleProceed}
          />}
        {tabIndex === 'broadCaster'
          && activeStep === 1
          && <BroadcasterPack
            selectedPack={selectedPack}
            onChange={selectPackHandler}
            data={searchInput.length > 2 ? tabSearchedData : tabFilteredData}
            handleNext={handleNext}
            isDashboard={isDashboard}
            handleProceed={handleProceed}
          />}
        {activeStep === 2
          && <SelectSubId
            handleChange={handleSelectSubId}
            selectedStbId={selectedStbId}
            handleProceed={handleProceed}
            handleBack={() => SetactiveStep(1)}
            loading={loading}
            showMore={showMore}
            getBoxIdList={getBoxIdList}
            searchedList={searchedList}
            boxlistData={boxlistData}
          />}
      </div>
      <Modal />
      <ToastInfo />
    </div>
  )
}

export default BulkPackModify;
