import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import {PATHS} from "../../../../constants";
import userImage from '../../../../assets/images/icon-avatar.png';
import './profile.scss';
import {commonService} from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import Loader from "../../../Loader";
import {getLocalStorage} from '../../../../services/commonUtils';
import changePasswordLogo from "../../../../assets/images/reset-password.png"


class Profile extends Component {
    state = {
        userDropdown: false,
        userName: localStorage.getItem("key") ? `${atob(localStorage.getItem("key"))}` : "User",
        chainId: localStorage.getItem("chKeyId") ? `${atob(localStorage.getItem("chKeyId"))}` : "",
      isLoading: false
    };
    myRef = React.createRef(null);

    componentDidMount(){
        const userType = getLocalStorage('admin');
        if(userType){
            this.setState({
                userName: userType.name
            })
        }
    }

    toggleUserDropdown = () => {
        const {userDropdown} = this.state;
        this.setState({
            userDropdown: !userDropdown
        })
    };

    signOut = async () => {
        this.setState({isLoading: true});
        const {history} = this.props;
        const userType = getLocalStorage('admin');
        if(userType && userType.role){
            localStorage.clear();
            sessionStorage.clear();
            history.push(PATHS.ADMIN);
        } else {
            try {
                const response = await commonService.clearHardResetStatus();
                const responseData = response.data;
                if(responseData.status) {
                  localStorage.clear();
                  sessionStorage.clear();
                  history.push(PATHS.HOME);
                }
              } catch {
                this.setState({error: GlobalConfig.serverError, isLoading: false})
              }
        }
    };
    closeOpenMenus = (e)=>{
        const {userDropdown} = this.state;
        if(this.myRef.current && userDropdown && !this.myRef.current.contains(e.target)){
            this.setState({
                userDropdown: false
            })
        }
    }

    render() {
        document.addEventListener('mousedown',this.closeOpenMenus)
        const {userDropdown, userName, isLoading, chainId} = this.state;
        const {toggleUserDropdown, signOut} = this;
        const userType = getLocalStorage('admin');
        return (
            <div className="user-info">
              {
                  isLoading && <Loader />
              }
                <img src={userImage} alt="User" className="mr10"/>
                <div className={userDropdown ? 'detail open' : 'detail'} onClick={toggleUserDropdown} ref={this.myRef}>
                    <div className="greeting">
                        Welcome
                    </div>
                    <div className="name" title={userName}>
                      {userName}
                      {
                        chainId ? <span className="d-flex">{chainId}</span> : null
                      }
                    </div>
        
                    <i className="icon">
                        <svg width="11" height="7">
                            <path fill="#343940" fillRule="evenodd"
                                  d="M5.432 6.438c-.224.011-.414-.11-.575-.278-1.381-1.445-2.76-2.893-4.14-4.34C.493 1.585.373 1.309.471.974.618.473 1.18.277 1.59.587c.309.233.551.543.819.822.949.987 1.894 1.976 2.83 2.975.162.173.253.157.408-.007C6.78 3.177 7.921 1.987 9.06.793c.193-.202.397-.371.696-.354.532.031.858.642.594 1.128a1.563 1.563 0 0 1-.252.325L6.1 6.083c-.181.189-.371.363-.668.355z"/>
                        </svg>
                    </i>

                    <ul className="user-dropdown">
                        {userType && userType.role ? null : <><li className="option">
                            <i className="o-icon">
                                <svg width="15" height="15">
                                    <path fill="#343940" fillRule="evenodd"
                                          d="M7.588 14.214c1.843 0 3.685.005 5.528-.003.727-.003 1.298-.357 1.401-.96.219-1.29.027-2.566-1.305-3.386-1.629-1.003-3.436-1.505-5.337-1.57-1.908-.065-3.709.434-5.418 1.277-1.272.627-1.919 1.614-1.86 3.064.045 1.094.5 1.576 1.603 1.578 1.796.004 3.592.001 5.388 0zm0-13.995c-2.02.022-3.482 1.524-3.471 3.566.01 1.808 1.63 3.401 3.448 3.39 1.967-.011 3.514-1.595 3.497-3.579C11.046 1.722 9.478.198 7.588.219z"/>
                                </svg>
                            </i>
                            <Link to={PATHS.PROFILE}>
                                My Profile
                            </Link>
                        </li>
                        <li className="option">
                        <i className="o-icon">
                            <img src={changePasswordLogo} alt="" height="17" width="16"/>
                        </i>
                           <Link to={PATHS.CHANGE_PASSWORD}>
                              Change Password
                            </Link>
                        </li>
                        </>
                        }
                        <li className="option" onClick={signOut}>
                            <i className="o-icon">
                                <svg width="15" height="17">
                                    <path fill="#343940" fillRule="evenodd"
                                          d="M14.977 9.814a6.875 6.875 0 0 1-1.675 4.011c-1.166 1.362-2.66 2.214-4.464 2.527-3.718.645-7.311-1.437-8.473-4.888C.117 10.729.016 10.101 0 9.271c.102-2.682 1.285-4.774 3.686-6.199.455-.27.969-.159 1.222.245.255.41.116.883-.347 1.155-1.586.931-2.541 2.282-2.792 4.047-.245 1.728.266 3.263 1.508 4.541 1.283 1.318 2.893 1.881 4.754 1.745 2.602-.19 4.806-2.186 5.186-4.679.279-1.835-.27-3.423-1.592-4.767-.367-.373-.8-.669-1.259-.932-.41-.233-.54-.693-.325-1.087a.861.861 0 0 1 1.171-.329c1.544.859 2.642 2.088 3.292 3.685a6.748 6.748 0 0 1 .473 3.118zM7.456 6.085c-.469-.012-.808-.368-.813-.876-.008-.642-.002-1.283-.002-1.925H6.64c0-.635-.002-1.27 0-1.906.002-.472.264-.804.694-.891.547-.111 1.023.286 1.026.865.005.728.002 1.457.002 2.186 0 .56.005 1.121-.003 1.681-.006.533-.374.879-.903.866z"/>
                                </svg>
                            </i>
                            Signout
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default withRouter(Profile);