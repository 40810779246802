import React from 'react';
import { Collapse } from 'reactstrap';
// import PropTypes from 'prop-types';
// import CustomButton from '../../Common/Button';
// import PackCard from '../PackCard';
import './style.scss';
import PackGroup from '../../BulkPackModify/BroadcasterPack/PackGroup';



const PackIndependentAccordion = (props) => {
  const {
    accordionData, toggleAccordian, isOpen, onChange, selectedPack,
  } = props;
  const cardHeaderStyle = {};
  // const [button, setButton] = useState({
  //   buttonConfig: {
  //     type: 'button',
  //     class: 'secondary',
  //   },
  //   text: 'Load More',
  // })

  return (<>
    <div className="card-header" style={cardHeaderStyle} onClick={toggleAccordian}>
      <span className={isOpen ? '' : 'collapsed'}>
        <h5 className="mb-0">
          {accordionData.name}
          <i className="icon-down-open-big rotate-icon" />
        </h5>
      </span>
    </div>

    <Collapse isOpen={isOpen || accordionData.hideAccordionTitle}>
      <div className="pack-bundle">
        <PackGroup onChange={onChange} data={accordionData.packages} selectedPack={selectedPack} />
      </div>
    </Collapse>
  </>
  );
}


export default PackIndependentAccordion;
