import React, {useState,useEffect} from 'react';
import searchIcon from '../../../assets/images/icon-search.svg';
import './style.scss';

const SearchBar = (props) => {
    const { placeholder , onChange, value, ...rest } = props;
    const [searchValue, setSearchValue] = useState(value)
    const onChangeHandler = (value) => {
        setSearchValue(value)
        onChange(value)
    }
    useEffect(() => {
        setSearchValue(value)
    },[value])
return (
    <div className='search-bar-outer'>
        <input className='search-bar' type="text" placeholder={placeholder} onChange={e => onChangeHandler(e.target.value)} {...rest} value={searchValue}/>
        <div className='search-icon'><img src={searchIcon} alt="" height="15" width="15" /></div>
    </div>
)
}

export default SearchBar;