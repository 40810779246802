import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import './balance-details-modal.scss';
import {commonService} from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import Loader from "../../../Loader";
import {dateFormatter} from '../../../../helper';
import {MESSAGES} from "../../../../constants";

class BalanceDetailsModal extends Component {
  state = {
    isLoading: true,
    balanceDetail: {},
    error: ''
  };

  componentDidMount() {
    this.getBalanceDetails()
        .catch(() => {
          console.error(MESSAGES.DID_MOUNT_ERROR);
        });
  }

  getBalanceDetails = async () => {
    const {modalParameters: {subscriberId, connectionLength}} = this.props;
    const payload = {
      subscriberId,
      noOfRows: connectionLength
    };
    try {
      const response = await commonService.balanceDetailApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        const updatedResponseObj = responseData.data;
        updatedResponseObj.nextRechargeDate = dateFormatter(updatedResponseObj.nextRechargeDate);
        this.setState({ balanceDetail: updatedResponseObj, isLoading: false });
      } else {
        this.setState({ error: responseData.message, isLoading: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false })
    }
  };

  render() {
    const {balanceDetail, isLoading, error} = this.state;
    const {modalParameters: {connectionSerial}} = this.props;
    return (
      <Fragment>
        {
          isLoading && <Loader />
        }
        <div className="b2b-modal-header pb35 pl20 pr20">
          {connectionSerial}
        </div>
        {
          !error ? (
            <div className="b2b-modal-info pl20 pr20">
              <div className="column">
                <div className="title">
                  Account Balance
                </div>
                <div className="value">
                  &#8377; {balanceDetail.accountBalance ? Number(balanceDetail.accountBalance).toFixed(2) : 0}
                </div>
              </div>

              <div className="column">
                <div className="title">
                  Monthly Charges
                </div>
                <div className="value">
                  &#8377; {balanceDetail.monthlyCharge ? Number(balanceDetail.monthlyCharge).toFixed(2) : 0}
                </div>
              </div>

              <div className="column">
                <div className="title">
                  Next Recharge Date
                </div>
                <div className="value">
                  {balanceDetail.nextRechargeDate || '-'}
                </div>
              </div>
            </div>
          ) : <div>{error}</div>
        }
      </Fragment>
    );
  }
}

BalanceDetailsModal.propTypes = {
  serial: PropTypes.string,
  accountBalance: PropTypes.number,
  monthlyCharges: PropTypes.number,
  nextRechargeDate: PropTypes.string
};

BalanceDetailsModal.defaultProps = {
  serial: '',
  accountBalance: 0,
  monthlyCharges: 0,
  nextRechargeDate: ''
};

export default BalanceDetailsModal;
