import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {addDays, compareDesc, subDays} from "date-fns";

class SecondaryConnection extends Component {
    state = {
      newDate: new Date(),
      nextDate: addDays(new Date(), 1)
    };

    handleDateChange = (key, date) => {
        this.setState({
            [key]: date
        });
    };

    render() {
        const { handleDateChange } = this;
        const { data, getSuspendResumeDetail, radioButtonChangeHandler, checked, primarySuspended } = this.props;
        const { newDate, nextDate } = this.state;
        return (
            <div className="secondary-connection">
                <div className="row mrl5">
                  {
                      data && data.map((item,index) => {
                          const startKeyName = `secondary-start-${index}`;
                          const endKeyName = `secondary-end-${index}`;
                          const vcStatus = item.vcStatus === "Active" ? "Suspend" : "Resume";
                          const minDate = vcStatus === "Suspend" ? nextDate : newDate;
                          const startDate = this.state[startKeyName] || (vcStatus === "Suspend" ? nextDate : newDate);
                          const toDate = this.state[endKeyName] || (vcStatus === "Suspend" ? addDays(startDate, 4) : newDate);
                          const minResumeDate = vcStatus === "Suspend" ? addDays(startDate, 4) : startDate;
                          const compareToDate = vcStatus === "Suspend" ? subDays(toDate, 4) : toDate;
                          const endDate = compareDesc(startDate, compareToDate) === 1 ? toDate : minResumeDate;


                          return (
                            <div className="col-md-4 pl20" key={index}>
                              <div className="d-flex mb20">
                                <label className={primarySuspended === "Suspended" ? "radio-element disabled" : "radio-element"}>
                                  <input
                                    type="radio"
                                    name="connection"
                                    onChange={() => { radioButtonChangeHandler(item.vcNumber) }}
                                    id={`secondary-${index}`}
                                    checked={checked === item.vcNumber}
                                    disabled={primarySuspended === "Suspended"}
                                  />
                                  <span className="select-radio" />
                                </label>

                                <div className="pr40">
                                  <div className="title">
                                    Secondary {index + 1}
                                  </div>
                                  <div className="information gradient-text">
                                    {item.vcAlias}
                                  </div>
                                </div>
                              </div>

                              <div className="pl40 mb20">
                                <div className="title">
                                  Digi Card Number
                                </div>
                                <div className="information">
                                  {item.vcNumber}
                                </div>
                              </div>

                              <div className="mb30">
                                <div className={(checked !== item.vcNumber) ? "date-picker-container disabled mb20 mr20" : "date-picker-container mb20 mr20"}>
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => { handleDateChange(startKeyName, date)}}
                                    onSelect={this.handleSelect}
                                    minDate={minDate}
                                    showDisabledMonthNavigation
                                    disabled={(checked !== item.vcNumber)}
                                  />
                                  <i className="calendar-icon">
                                    <svg width="18" height="18">
                                      <path fill="#353A41" fillRule="evenodd" d="M15.188 18H2.813A2.816 2.816 0 0 1 0 15.188V4.219a2.816 2.816 0 0 1 2.813-2.813h.878V.703c0-.388.315-.703.703-.703.007 0 .012.004.018.004.006 0 .011-.004.018-.004.388 0 .703.315.703.703v.703h3.129V.703c0-.388.315-.703.703-.703.012 0 .023.007.035.007.012 0 .023-.007.035-.007.389 0 .703.315.703.703v.703h3.129V.703c0-.388.315-.703.703-.703.007 0 .012.004.018.004.006 0 .011-.004.017-.004.389 0 .703.315.703.703v.703h.88A2.816 2.816 0 0 1 18 4.219v10.969A2.816 2.816 0 0 1 15.188 18zm1.406-13.781c0-.776-.631-1.406-1.406-1.406h-.88v.703a.703.703 0 0 1-.703.703c-.006 0-.011-.004-.017-.004-.006 0-.011.004-.018.004a.703.703 0 0 1-.703-.703v-.703H9.738v.703a.703.703 0 0 1-.703.703c-.012 0-.023-.007-.035-.007-.012 0-.023.007-.035.007a.703.703 0 0 1-.703-.703v-.703H5.133v.703a.703.703 0 0 1-.703.703c-.007 0-.012-.004-.018-.004-.006 0-.011.004-.018.004a.703.703 0 0 1-.703-.703v-.703h-.878c-.776 0-1.406.63-1.406 1.406v10.969c0 .775.63 1.406 1.406 1.406h12.375c.775 0 1.406-.631 1.406-1.406V4.219zm-3.024 9.949a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.094a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-2.988a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.058 6.082a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.094a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-2.988a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.059 6.117a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.058a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.059a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.059 6.117a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.058a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.059a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407z" />
                                    </svg>
                                  </i>
                                </div>

                                <div className={(checked !== item.vcNumber) ? "date-picker-container to-label secondary disabled mb20 mr20" : "date-picker-container to-label secondary mb20 mr20"}>
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    onChange={(date) => { handleDateChange(endKeyName, date)}}
                                    onSelect={this.handleSelect}
                                    minDate={minResumeDate}
                                    showDisabledMonthNavigation
                                    disabled={(checked !== item.vcNumber)}
                                  />
                                  <i className="calendar-icon">
                                    <svg width="18" height="18">
                                      <path fill="#353A41" fillRule="evenodd" d="M15.188 18H2.813A2.816 2.816 0 0 1 0 15.188V4.219a2.816 2.816 0 0 1 2.813-2.813h.878V.703c0-.388.315-.703.703-.703.007 0 .012.004.018.004.006 0 .011-.004.018-.004.388 0 .703.315.703.703v.703h3.129V.703c0-.388.315-.703.703-.703.012 0 .023.007.035.007.012 0 .023-.007.035-.007.389 0 .703.315.703.703v.703h3.129V.703c0-.388.315-.703.703-.703.007 0 .012.004.018.004.006 0 .011-.004.017-.004.389 0 .703.315.703.703v.703h.88A2.816 2.816 0 0 1 18 4.219v10.969A2.816 2.816 0 0 1 15.188 18zm1.406-13.781c0-.776-.631-1.406-1.406-1.406h-.88v.703a.703.703 0 0 1-.703.703c-.006 0-.011-.004-.017-.004-.006 0-.011.004-.018.004a.703.703 0 0 1-.703-.703v-.703H9.738v.703a.703.703 0 0 1-.703.703c-.012 0-.023-.007-.035-.007-.012 0-.023.007-.035.007a.703.703 0 0 1-.703-.703v-.703H5.133v.703a.703.703 0 0 1-.703.703c-.007 0-.012-.004-.018-.004-.006 0-.011.004-.018.004a.703.703 0 0 1-.703-.703v-.703h-.878c-.776 0-1.406.63-1.406 1.406v10.969c0 .775.63 1.406 1.406 1.406h12.375c.775 0 1.406-.631 1.406-1.406V4.219zm-3.024 9.949a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.094a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-2.988a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.058 6.082a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.094a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-2.988a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.059 6.117a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.058a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.059a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.059 6.117a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.058a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.059a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407z" />
                                    </svg>
                                  </i>
                                </div>

                                <button
                                  className="button fill"
                                  onClick={getSuspendResumeDetail(item, endDate, startDate, vcStatus)}
                                  disabled={(checked !== item.vcNumber)}
                                >
                                  {
                                    vcStatus
                                  }
                                </button>
                              </div>
                            </div>
                          )
                      })
                  }

                </div>
            </div>
        )
    }
}

export default SecondaryConnection;
