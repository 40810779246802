import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom';
import { getSession } from '../../../services/commonUtils';
// import CustomButton from '../Button';
import './style.scss';
import { isEmpty } from 'lodash';
import { PATHS } from '../../../constants';

const OperationSuccess = (Props) => {
    const history = useHistory();
    const [pageData] = useState(getSession('bulkOpetaionResponse') || null)

    useEffect(() => {
        if (isEmpty(pageData))
            history.push('/')
        else
            sessionStorage.removeItem('bulkOpetaionResponse');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className='success-page'>
            <div className='text-center'>
                <div className="svg-icon-right">
                    {pageData && pageData.status ? <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                        className=""
                        style={{
                            position: "relative",
                            top: "10px",
                            marginBottom: "20px",
                        }}
                        data-wow-delay="0.8s"
                    >
                        <circle
                            className="path circle"
                            fill="#ffffff"
                            stroke="#009822"
                            strokeWidth="6"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <polyline
                            className="path check"
                            fill="none"
                            stroke="#009822"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                    </svg>
                        :
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle
                                className="path circle"
                                fill="none"
                                stroke="#FF0600"
                                strokeWidth="6"
                                strokeMiterlimit="10"
                                cx="65.1"
                                cy="65.1"
                                r="62.1"
                            />
                            <line
                                className="path line"
                                fill="none"
                                stroke="#FF0600"
                                strokeWidth="6"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                x1="34.4"
                                y1="37.9"
                                x2="95.8"
                                y2="92.3"
                            />
                            <line
                                className="path line"
                                fill="none"
                                stroke="#FF0600"
                                strokeWidth="6"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                x1="95.8"
                                y1="38"
                                x2="34.4"
                                y2="92.2"
                            />
                        </svg>
                    }

                </div>
            </div>
            <div className='details'>
                <div className='text-center'>
                    {pageData && pageData.message && <h3>{pageData.message}</h3>}
                    {pageData && pageData.data && <>
                    <p>Your Transaction ID is</p>
                    <h3>{pageData.data}</h3>
                    </>}
                    <p>You can track the status on Activities page</p>
                </div>
            </div>
            <div className='text-center'>
                {/* <CustomButton
                    content="Go to Acivities"
                    classes="primary"
                    config={{ type: 'button' }}
                    onClick={handleRedirection}
                    />*/}
                <Link to={PATHS.ACTIVITIES} className="button fill">
                    Go to Activities
                </Link>
            </div>
        </div>
    )
}

export default OperationSuccess;