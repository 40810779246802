import React, { Component } from "react";
import PropTypes from "prop-types";

class ReCaptcha extends Component {
  constructor(props) {
    super(props);

    this.loadRecaptcha = this.loadRecaptcha.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (document.readyState === "complete") {
      // Window was already loaded (the component is rendered later on)
      // ReCaptacha can be safely loaded
      this.loadRecaptcha();
    } else {
      // Wait that the window gets loaded to load the ReCaptcha
      window.onload = this.loadRecaptcha;
    }
  }

  loadRecaptcha() {
    const { id, theme, getRef, resetId } = this.props;
    const captchaKey = window.location.origin.includes('myb2b.tataplay.com') ? "6LcyQ-sUAAAAAGb5nyVY9CnZJiTYIARdrkWbe3EA" : "6LcHQ-sUAAAAADAsbgB-bTaKWa0xz8gaGKLMwfey" ;    
    // console.log('captchaKey',captchaKey)
    this.recatchaElt = window.grecaptcha.render(id, {
      sitekey: captchaKey || "6LcyQ-sUAAAAAGb5nyVY9CnZJiTYIARdrkWbe3EA", 
      // sitekey: apiKey || "6LcyQ-sUAAAAAGb5nyVY9CnZJiTYIARdrkWbe3EA", //prod
      // sitekey: apiKey || "6LcHQ-sUAAAAADAsbgB-bTaKWa0xz8gaGKLMwfey", //QA
      theme,
      callback: this.handleChange,
      "expired-callback": this.handleChange,
      "error-callback": this.handleChange,
      size: "invisible",
      render: "explicit",
    });
    getRef(window.grecaptcha);
    resetId(this.recatchaElt);
  }

  handleChange(token) {
    const { onChange } = this.props;
    onChange(token);
  }

  render() {
    const { id } = this.props;

    return <div id={id} />;
  }
}

ReCaptcha.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(["dark", "light"])
};

ReCaptcha.defaultProps = {
  theme: "light"
};

export default ReCaptcha;
