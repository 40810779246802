import React, { useState, useEffect } from 'react'
import { commonService } from '../../../storeManager/common/services'
import GlobalConfig from '../../../globalConfig/globalConfig'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// import {get} from "lodash";
import { addDays, subDays, compareDesc } from "date-fns";
import Calender from '../../../assets/images/calendar.png'
import './style.scss'
import { setSession } from '../../../services/commonUtils';
import { useHistory } from 'react-router-dom'
import CustomButton from '../../Common/Button';
import Loader from '../../Loader';
import { getDateFormatYYYYMMDD } from '../../../helper'

function SelectDates(props) {
    const [newDate] = useState(new Date())
    const [nextDate] = useState(addDays(new Date(), 1))
    const [newDateError, setNewDateError] = useState(null)
    const [nextDateError, setNextDateError] = useState(null)
    const [startKey, setStartKey] = useState()
    const [endKey, setEndKey] = useState()
    // const [payloadData, setPayloadData] = useState()
    const [isLoading, setIsLoading] = useState(false);

    // const dispatch = useDispatch();
    const history = useHistory();

    const { operation, boxlistData, selectedStbId, handleBack, selectedSubId } = props;

    useEffect(() => {
        // const sessiondata = sessionStorage.getItem("bulksubid")
        // const filterdata = boxlistData && boxlistData.boxlist.filter((user) => selectedSubId.includes(user.subscriberId));
        // setPayloadData(filterdata)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // const { data, getSuspendResumeDetail, radioButtonChangeHandler, checked } = this.props;
    const startKeyName = "startKey";
    const endKeyName = "endKey";
    // const vcStatus = get(data, "vcStatus") === "Active" ? "Suspend" : "Resume";
    const minDate = operation === "Suspend" ? nextDate : newDate;
    const startDate = startKey || (operation === "Suspend" ? nextDate : newDate);
    const toDate = endKey || (operation === "Suspend" ? addDays(startDate, 4) : newDate);
    const minResumeDate = operation === "Suspend" ? addDays(startDate, 4) : startDate;
    const compareToDate = operation === "Suspend" ? subDays(toDate, 4) : toDate;
    const endDate = compareDesc(startDate, compareToDate) === 1 ? toDate : minResumeDate;



    const bulkStatusOperation = async () => {
        if ((newDate && nextDate) || (operation === "Resume" && nextDate)) {
            const payload = {
                "subIdStb": selectedSubId.map(item => item),
                "resDate": getDateFormatYYYYMMDD(endDate),
                "susDate": operation === "Suspend" ? getDateFormatYYYYMMDD(startDate) : '', //"2023-02-28",
                "stbType": boxlistData && boxlistData.boxlist.find(f => f.stbNumber === selectedStbId[0]).connectionType,
                "actionType": operation,
                "activityType": `Bulk ${operation}`
            };
            
            setIsLoading(true)
            try {
                const response = await commonService.bulkSuspend(payload);
                const responseData = response.data;
                setSession('bulkOpetaionResponse', responseData)
                if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                    // const data = responseData.data;
                    sessionStorage.removeItem('sbl');
                    sessionStorage.removeItem('ct');
                    setIsLoading(false)
                }else{
                    setIsLoading(false)
                }
                history.push('/operation-success')
            } catch {
                setIsLoading(false)
                //   console.error(MESSAGES.DID_MOUNT_ERROR);
            }
        } else if (!newDate && nextDate) {
            setNewDateError("Please select date")
        }
        else if (newDate && !nextDate) {
            setNextDateError('Please select date')
        }
        else {
            setNewDateError("Please select date")
            setNextDateError('Please select date')
        }

    }

    const handleDateChange = (date, name) => {
        if (name === 'startKey') {
            setStartKey(date)
            setNewDateError(null)
        } else if (name === 'endKey') {
            setEndKey(date)
            setNextDateError(null)
        }
    };
    if (isLoading)
    return (<Loader />)
    return (
        <div className='select-date-wrap'>
            <p>{operation === "Resume" ? 'Please select the date from when you want to resume service' : 'Please select the date till when you want to temporarily suspend?'}</p>
            <div className='formdiv'>
                <div className='lablediv'>
                    <label> From </label>
                    <div className="forminput">
                        <DatePicker
                            className='datePicker'
                            dateFormat="dd/MM/yyyy"
                            selected={startDate}
                            onChange={(date) => { handleDateChange(date, startKeyName) }}
                            //    onSelect={this.handleSelect}
                            minDate={minDate}
                            //    disabled={(checked !== keyName)}
                            showDisabledMonthNavigation
                        /><img src={Calender} alt="" width="17" height='17' />
                    </div>
                    <span className='error-msg'>{newDateError}</span>
                </div>
                <div className='lablediv'>
                    <label> To </label>
                    <div className="forminput">
                        <DatePicker className='datePicker'
                            dateFormat="dd/MM/yyyy"
                            selected={endDate}
                            onChange={(date) => { handleDateChange(date, endKeyName) }}
                            //  onSelect={this.handleSelect}
                            minDate={minResumeDate}
                            //  disabled={(checked !== keyName)}
                            showDisabledMonthNavigation
                        /><img src={Calender} alt="" width="17" height='17' />
                    </div>
                    <span className='error-msg'>{nextDateError}</span>
                </div>
            </div>
            <div className='bulkOperationFooter'>
                <div className='footerWrapper'>
                    <CustomButton
                        content="Back"
                        classes="primary"
                        config={{ type: 'button', class: 'secondary' }}
                        onClick={handleBack}
                    />
                    <CustomButton
                        content={operation}
                        classes="primary"
                        config={{ type: 'button' }}
                        onClick={bulkStatusOperation}
                    />
                </div>
            </div>
        </div>
    )
}

export default SelectDates
