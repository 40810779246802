import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {get} from "lodash";
import {addDays, subDays, compareDesc} from "date-fns";

// const keyName = "primaryConnection";

class PrimaryConnection extends Component {

  state={
    newDate: new Date(),
    nextDate: addDays(new Date(), 1),
  };

  handleDateChange = (key, date) => {
    this.setState({
      [key]: date
    });
  };

  render() {
    const { handleDateChange } = this;
    const { data, getSuspendResumeDetail, radioButtonChangeHandler, checked } = this.props;
    const { newDate, nextDate } = this.state;
    const startKeyName = "startKey";
    const endKeyName = "endKey";
    const vcStatus = get(data, "vcStatus") === "Active" ? "Suspend" : "Resume";
    const minDate = vcStatus === "Suspend" ? nextDate : newDate;
    const startDate = this.state[startKeyName] || (vcStatus === "Suspend" ? nextDate : newDate);
    const toDate = this.state[endKeyName] || (vcStatus === "Suspend" ? addDays(startDate, 4) : newDate);
    const minResumeDate = vcStatus === "Suspend" ? addDays(startDate, 4) : startDate;
    const compareToDate = vcStatus === "Suspend" ? subDays(toDate, 4) : toDate;
    const endDate = compareDesc(startDate, compareToDate) === 1 ? toDate : minResumeDate;
    const vcNumber = get(data, "vcNumber")

    return (
      <div className="separate-line mb20 p20">
        <div className="d-flex mb20">
          <label className="radio-element">
            <input
              type="radio"
              name="connection"
              checked={checked === vcNumber }
              onChange={() => { radioButtonChangeHandler(vcNumber)}}
            />
            <span className="select-radio"/>
          </label>

          <div className="pr40">
            <div className="title">
              Primary Connection
            </div>
            <div className="information gradient-text">
              {get(data, "vcAlias")}
            </div>
          </div>

          <div className="pl40">
            <div className="title">
              Digi Card Number
            </div>
            <div className="information">
              {get(data, "vcNumber")}
            </div>
          </div>
        </div>
        <div className="mb20 d-flex">
          <div className={(checked !== vcNumber) ? "date-picker-container disabled mr20" : "date-picker-container mr20"}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => { handleDateChange(startKeyName, date)}}
              onSelect={this.handleSelect}
              minDate={minDate}
              disabled={(checked !== vcNumber)}
              showDisabledMonthNavigation
            />
            <i className="calendar-icon">
              <svg width="18" height="18">
                <path fill="#353A41" fillRule="evenodd"
                      d="M15.188 18H2.813A2.816 2.816 0 0 1 0 15.188V4.219a2.816 2.816 0 0 1 2.813-2.813h.878V.703c0-.388.315-.703.703-.703.007 0 .012.004.018.004.006 0 .011-.004.018-.004.388 0 .703.315.703.703v.703h3.129V.703c0-.388.315-.703.703-.703.012 0 .023.007.035.007.012 0 .023-.007.035-.007.389 0 .703.315.703.703v.703h3.129V.703c0-.388.315-.703.703-.703.007 0 .012.004.018.004.006 0 .011-.004.017-.004.389 0 .703.315.703.703v.703h.88A2.816 2.816 0 0 1 18 4.219v10.969A2.816 2.816 0 0 1 15.188 18zm1.406-13.781c0-.776-.631-1.406-1.406-1.406h-.88v.703a.703.703 0 0 1-.703.703c-.006 0-.011-.004-.017-.004-.006 0-.011.004-.018.004a.703.703 0 0 1-.703-.703v-.703H9.738v.703a.703.703 0 0 1-.703.703c-.012 0-.023-.007-.035-.007-.012 0-.023.007-.035.007a.703.703 0 0 1-.703-.703v-.703H5.133v.703a.703.703 0 0 1-.703.703c-.007 0-.012-.004-.018-.004-.006 0-.011.004-.018.004a.703.703 0 0 1-.703-.703v-.703h-.878c-.776 0-1.406.63-1.406 1.406v10.969c0 .775.63 1.406 1.406 1.406h12.375c.775 0 1.406-.631 1.406-1.406V4.219zm-3.024 9.949a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.094a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-2.988a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.058 6.082a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.094a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-2.988a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.059 6.117a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.058a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.059a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.059 6.117a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.058a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.059a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407z"/>
              </svg>
            </i>
          </div>

          <div className={(checked !== vcNumber) ? "date-picker-container to-label secondary disabled mr20" : "date-picker-container to-label secondary mr20"}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => { handleDateChange(endKeyName, date)}}
              onSelect={this.handleSelect}
              minDate={minResumeDate}
              disabled={(checked !== vcNumber)}
              showDisabledMonthNavigation
            />
            <i className="calendar-icon">
              <svg width="18" height="18">
                <path fill="#353A41" fillRule="evenodd"
                      d="M15.188 18H2.813A2.816 2.816 0 0 1 0 15.188V4.219a2.816 2.816 0 0 1 2.813-2.813h.878V.703c0-.388.315-.703.703-.703.007 0 .012.004.018.004.006 0 .011-.004.018-.004.388 0 .703.315.703.703v.703h3.129V.703c0-.388.315-.703.703-.703.012 0 .023.007.035.007.012 0 .023-.007.035-.007.389 0 .703.315.703.703v.703h3.129V.703c0-.388.315-.703.703-.703.007 0 .012.004.018.004.006 0 .011-.004.017-.004.389 0 .703.315.703.703v.703h.88A2.816 2.816 0 0 1 18 4.219v10.969A2.816 2.816 0 0 1 15.188 18zm1.406-13.781c0-.776-.631-1.406-1.406-1.406h-.88v.703a.703.703 0 0 1-.703.703c-.006 0-.011-.004-.017-.004-.006 0-.011.004-.018.004a.703.703 0 0 1-.703-.703v-.703H9.738v.703a.703.703 0 0 1-.703.703c-.012 0-.023-.007-.035-.007-.012 0-.023.007-.035.007a.703.703 0 0 1-.703-.703v-.703H5.133v.703a.703.703 0 0 1-.703.703c-.007 0-.012-.004-.018-.004-.006 0-.011.004-.018.004a.703.703 0 0 1-.703-.703v-.703h-.878c-.776 0-1.406.63-1.406 1.406v10.969c0 .775.63 1.406 1.406 1.406h12.375c.775 0 1.406-.631 1.406-1.406V4.219zm-3.024 9.949a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.094a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-2.988a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.058 6.082a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.094a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-2.988a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.059 6.117a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.058a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.059a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm-3.059 6.117a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.058a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407zm0-3.059a.703.703 0 1 1 0-1.407.703.703 0 0 1 0 1.407z"/>
              </svg>
            </i>
          </div>

          <button
            className="button fill mr20 suspend-primary-btn"
            onClick={getSuspendResumeDetail(data, endDate, startDate, vcStatus)}
            disabled={(checked !== vcNumber)}
          >
            {
              `${vcStatus} Primary`
            }
          </button>
          <div className="disclaimer-text">To suspend your primary box, please ensure your secondary boxes are active</div>
        </div>
      </div>
    )
  }
}

export default PrimaryConnection;