import React from 'react';
import EditableName from '../EditableName';
import ShowMoreDropbox from '../ShowMoreDropbox';
import { PATHS } from '../../../constants';
import { Link } from 'react-router-dom';

const TableColumns = (props) => {
    const { BoxNameUpdateInTable, rechargeRedirect, redirectModifyPack, handleResume, updateHeavyRefresh } = props;
    return [
        {
            key: "boxAlias",
            text: `<span>Box Friendly <br /> Name</span>`,
            formatter: (cell, row, rowIndex) => (<EditableName BoxNameUpdateInTable={BoxNameUpdateInTable} row={row} cell={cell} />)
        },
        {
            key: "subscriberId",
            text: `<span>Subscriber <br /> Id</span>`,

        },
        {
            key: "vcNumber",
            text: `<span>Digicard/CDSN</span>`,
        },
        {
            key: "boxType",
            text: `<span>Box<br />Type</span>`,
        },
        {
            key: "boxStatus",
            text: `<span>Box<br />Status</span>`,
        },
        {
            key: "connectionType",
            text: `<span>Connection<br />Type</span>`,
        },
        {
            key: "mbr",
            text: `<span>Current<br />MBR</span>`,
        },
        {
            key: "accountStatus",
            text: `<span>Account<br />Status</span>`,
        },
        {
            key: "",
            text: 'Action',
            formatter: (cell, row, rowIndex) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {row.boxStatus === 'Suspended' ? <button className='btn-secondary' onClick={() => handleResume(row, rowIndex + 1)}>Resume</button>
                            :
                            <Link className='btn-secondary' to={{
                                pathname: PATHS.TEMPORARILY_SUSPEND,
                                state: { vcNumber: row.vcNumber, subscriberId: row.subscriberId, actionType: row.boxStatus === 'Suspended' ? "Resume" : "Suspend", noOfRows: rowIndex + 1 }
                            }}>
                                {row.boxStatus === 'Suspended' ? "Resume" : "Suspend"}
                            </Link>}
                        {/* <button className='btn-secondary' onClick={((e)=>{getSuspendDetail(item.subscriberId,index,e)})}>Suspend</button> */}
                        <button className='btn-secondary' onClick={rechargeRedirect(row.subscriberId)}>Recharge</button>
                        <button className='btn-secondary' onClick={redirectModifyPack(row.subscriberId, row.name)}>Modify</button>
                    </div>
                )

            }
        },
        {
            key: "",
            text: 'More',
            formatter: (cell, row, rowIndex) => {
                return (
                    <ShowMoreDropbox row={row} heavyRefresh={updateHeavyRefresh} />
                )

            }
        }

        //  {
        //      key: "balance",
        //      text: "Balance"
        //  }
    ]
}
export default TableColumns;
