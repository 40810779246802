import React, { useState } from 'react'
import CustomButton from '../../Common/Button';
// import CustomTable from '../../Common/CustomTable';
import SelectItemBar from '../../Common/SelectItemBar';
// import TableColumns from '../TableColumns';
import { isEmpty } from 'lodash';
// import Loader from '../../Loader';
import ReactLoading from 'react-loading';

const AlLaCarte = (props) => {
    const { data, onChange, selectedPack, handleNext, isDashboard, handleProceed } = props;

    const [maxCount, setMaxCount] = useState(12);
    const [isLoading, setIsLoading] = useState(false);
    const handleLoadMore = () => {
        setIsLoading(true)
        setMaxCount(maxCount + 8)
        setTimeout(()=>{
            setIsLoading(false)
            window.scrollTo({ top: document.body.scrollHeight - 800, behavior: 'smooth' })
        },400)
    }
    const mapData = () => {
        const finalData = [];
        for (let i = 0; i < maxCount && i < data.length; i++) {
            finalData.push(
                <SelectItemBar
                    key={data[i].package_id}
                    name="al_la_carte_pack"
                    type="radio"
                    value={JSON.stringify({ 'name': data[i].pack_name, 'amount': data[i].pack_mrp })}
                    label={`${data[i].pack_name} @ ₹${data[i].pack_mrp}`}
                    id={data[i].package_id}
                    onChange={onChange}
                    selectedPack={selectedPack}
                />
            )
        }
        return finalData;
    }
  
    return (
        <div className='al-la-carte'>
        {/*isLoading && <Loader />*/}
            <div className='pack-group-wrap'>
                {
                    mapData()
                }
            </div>
            <div className='bulkOperationFooter'>
                <div className='footerWrapper'>
                {maxCount < data.length && <div className='bulkOperationFooter-loadmore-sec'>
                        {/* Showing <b>{maxCount > data.length ? data.length : maxCount}</b> / <b>{data.length}</b> */}
                    <CustomButton
                        onClick={handleLoadMore}
                        // content="Load More"
                        classes="primary"
                        config={{ type: 'button', class: 'secondary' }}
                    >
                        {isLoading ? <ReactLoading type={'bars'} color={'#E10092'} height={22} width={25} /> : <span>Load More Packs ({maxCount > data.length ? data.length : maxCount} / {data.length})</span>}
                </CustomButton>
                    </div>}
                    <div className='bulkOperationFooter-button-sec'>
                    {isDashboard ?
                        <CustomButton
                            content="Proceed"
                            classes="primary"
                            config={{ type: 'button' }}
                            onClick={handleProceed}
                            disabled={isEmpty(selectedPack)}
                        />
                        :
                        <CustomButton
                            content="Next"
                            classes="primary"
                            config={{ type: 'button' }}
                            onClick={handleNext}
                            disabled={isEmpty(selectedPack)}
                        />}
                        </div>
                </div>
            </div>
        </div>

    )
}

export default AlLaCarte;