import React  from 'react';
import './style.scss';

const SelectItemBar = (props) => {
    const { name, disabled, type, label, value, id, onChange, selectedPack, ...rest} = props;
    // const [isChecked, setIsChecked] = useState(false);

   
    return (
        <div className='select-item-bar'>
            <label className='medium-strong' htmlFor={id} >
                {label}
            </label>
            <input
                type={type}
                name={name}
                id={id}
                value={value}
                onChange={(e => onChange(e, value))}
                disabled={disabled}
                {...rest}
                checked={selectedPack === value}
            />
            <div className='check-design'></div>
        </div>
    )
}

export default SelectItemBar;