import React from 'react';
import "./style.scss";
import { isArray } from 'lodash';

const PackCard = (props) => {
    const { cardData: { pack_name, pack_mrp, genre, sd_count, hd_count }, value, type, name, disabled = false, onChange, id, selectedPack } = props;
    return (
        <div className='card-outer-wrap'>
            <input
                className='pack-input'
                type={type}
                name={name}
                value={value}
                onChange={(e => onChange(e, value))}
                disabled={disabled}
                id={id}
                checked={selectedPack === value}
            />
            <label htmlFor={id} className="card pack-details">
                <div className="card-body text-center">
                    {pack_name && <h2 className="packname">
                        {pack_name}
                    </h2>}
                    <div className="price-lg gradient">
                        <span className="rupee"> ₹ </span>
                        {parseFloat(pack_mrp).toFixed(2)}
                        <span className="support-text">/month</span>
                    </div>

                    <div className='channelnum mt20'>
                        <ul className="channellist">
                            {isArray(genre) && genre.length > 0 && <li>{`Best Of ${genre.join(', ')}`}</li>}
                            <li>{`SD Channel count ${sd_count}`}</li>
                            {hd_count > 0 ? <li>{`HD Channel count ${hd_count}`}</li> : null}

                        </ul>
                    </div>
                    <div className="clr-litgray mt20">Network Capacity Fee extra as applicable</div>
                </div>
            </label>
        </div>
    )
}

export default PackCard;