import React from 'react';

import ShowMoreDropbox from "../../TableWithAction/ShowMoreDropbox";

 const TableColumns = (props) => ([
    {
         key:"subscriberId",
        text: "Sub Id"
    },
     {
         key: "name",
         text: "Subscribe Name"
     },
     {
         key: "accountStatus",
         text: "Account Status"
     },
     {
         key: "mbr",
         text: "Current MBR"
     },
     {
        key: "",
        text: 'More',
        formatter: (cell, row, rowIndex) => {
            return (
                <ShowMoreDropbox row={row} />
            )

        }
    },
    //  {
    //      key: "balance",
    //      text: "Balance"
    //  }
 ])
export default TableColumns;
