import React, { useEffect } from 'react';
// import ModifyTable from './ModifyTable';
import './style.scss';


const ToggleSwitch = (props) => {
const {onChange, disabled, ...rest} = props
  useEffect(() => {

  }, [])

  return (
    <div className="toggle-switch">
      <label className="switch">
        <input disabled={disabled} type="checkbox" onChange={onChange} value='hd' {...rest}/>
        <span className="slider round"></span>
      </label>
    </div>
  )
}

export default ToggleSwitch;
