import React from 'react';
import "./style.scss"
const CustomButton = (props) => {
  const {
    content, config, disabled, redBorder, children, ...others
  } = props;
  const classes = ['animated1', 'fadeIn1', 'btn', 'medium-strong', "button"];
  if (redBorder) { classes.push('redBorder'); }
  switch (config.class) {
    case 'primary':
      classes.push('btn-primary');
      break;
    case 'secondary':
      classes.push('btn-secondary');
      break;
    default:
      classes.push('btn-primary');
      break;
  }
  return (
    <button
      type={config.type}
      disabled={disabled}
      className={classes.join(' ')}
      id={config.id ? config.id : null}
      {...others}
    >
      {content} {children}
    </button>
  );
}

export default CustomButton;

