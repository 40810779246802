import React from 'react';
import './style.scss';


const Stepper = (props) => {
  const { stepOne, stepTwo, activeStep } = props;

  return (
    <div className="stepper clearfix modify-step">
      <div className={`step medium-strong ${activeStep === 1 || activeStep !== 2 ? 'active' : ''}`}>
        <span className='step-circle'>1</span>
        <span>{stepOne}</span>
        </div>
      <div className={`step medium-strong ${activeStep === 2 ? 'active' : ''}`}>
      <span className='step-circle'>2</span>
        <span>{stepTwo}</span>
        </div>
    </div>
  )
}

export default Stepper;
