import React, { useEffect, useState } from 'react';
import TableWithAction from '../TableWithAction';
import { useHistory } from "react-router-dom"
// import ModifyTable from './ModifyTable';
import './style.scss';
import { isEmpty } from 'lodash'
import { commonService } from '../../storeManager/common/services'
import { API_REQUEST_KEYS } from "../../constants";
import { useDispatch } from 'react-redux'
import GlobalConfig from "../../globalConfig/globalConfig";
import { MODALS } from '../../storeManager/modal/constants'
import { openModal } from '../../storeManager/modal/actions'
import CustomButton from '../Common/Button';
import Stepper from '../Common/stepper';
import SelectDates from './SelectDates/index';
import { getSession, setSession } from '../../services/commonUtils';
import Modal from '../Common/Modal';
import { hideToastInfo, showToastInfo } from '../../storeManager/common/actions';
import ToastInfo from '../Common/ToastInfo';
import { getDateFormatYYYYMMDD } from "../../helper";
import Loader from '../Loader';

const BulkStatusUpdate = (props) => {
  const [boxlistData, setBoxlistData] = useState({ boxlist: [] });
  const [selectedStbId, setSelectedStbId] = useState([]);
  const [selectedSubId, setSelectedSubId] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageCount, setpageCount] = useState('');
  const [activeStep, setActiveStep] = useState(1);
  const [operation, setOperation] = useState(null);
  const [isBack, setIsBack] = useState(false)
  const [pageLoading, setPageLoading] = useState(false);

  let count = pageCount || 0
  const dispatch = useDispatch();
  const history = useHistory();

  const recomendationParamInput = React.createRef();
  const getThisForm = React.createRef();
  
  useEffect(() => {
    getBoxIdList();
    return () => {
      sessionStorage.removeItem('sbl');
      sessionStorage.removeItem('ct');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  let updateData = [...boxlistData.boxlist];
  const getBoxIdList = async () => {
    setLoading(true)
    const payload = {
      "accountDetails": {
        "noOfRows": 200,
        "page": count,
        "requestType": API_REQUEST_KEYS.requestType.getBoxList,
        "viewRequestType": "DASHBOARD"
      }
    };
    try {
      const response = await commonService.subscriberListApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        let data = responseData.data;
        if (Array.isArray(data.subscriberList)) {
          updateData = updateData.concat(data.subscriberList);
          // setNewBoxData(updateData)
          setBoxlistData({
            boxlist: updateData,
            noDataFound: data.noDataFound,
            isLoading: false,
            hasMoreItems: data.hasMoreItems,
            loadMoreLoader: false,
          })
          if (data.hasMoreItems && count < 4 && !showMore) {
            count = count + 1
            getBoxIdList()
          } else if (!data.hasMoreItems) {
            setShowMore(false)
            setLoading(false)
          } else {
            setpageCount(count + 1)
            setShowMore(true)
            setLoading(false)
          }
        } else if (isEmpty(data.subscriberList) || !data.hasMoreItems) {
          setpageCount(0)
          setShowMore(false)
          setLoading(false)
        }
      } else {
        dispatch(openModal(MODALS.CONFIRMATION_POPUP,
          {
            message: responseData.message,
            Dashboard: true,
            reDirectLinkHide: true,
            getBoxIdList,
          }))
        // this.setState({ error: responseData.message, isLoading: false, hasMoreItems: false, loadMoreLoader: false })
      }
    } catch {
      // this.setState({ error: GlobalConfig.serverError, isLoading: false, hasMoreItems: false, loadMoreLoader: false })
    }
  };
  // handle for Sub ids selection 
  const handleSelectSubId = (e, value, isAllSelected = false) => {
    let updatedStbId = [...selectedStbId];
    let updatedSubId = [...selectedSubId];
    dispatch(hideToastInfo())
    if (isAllSelected) {
      const stbIdList = [];
      const subIdList = [];
      if (value.length > 250) {
        value.slice(0, 250).forEach(el => {
          stbIdList.push(el.stbNumber)
          subIdList.push(`${el.subscriberId}|${el.stbNumber}`)

        })
        dispatch(showToastInfo("First 250 records have been selected, You can not select more than 250 records", 5000))
      } else {
        value.forEach(el => {
          stbIdList.push(el.stbNumber)
          subIdList.push(`${el.subscriberId}|${el.stbNumber}`)

        })
      }
      setSelectedStbId(stbIdList)
      setSelectedSubId(subIdList)
    }
    else {
      if (e.target.checked) {
        if (updatedStbId.length < 250) {
          updatedStbId.push(value.stbNumber);
          updatedSubId.push(`${value.subscriberId}|${value.stbNumber}`);
        } else {
          dispatch(showToastInfo("You can not select more than 250 records", 5000))
        }
      }
      else {
        updatedStbId = updatedStbId.filter(o => o !== value.stbNumber);
        updatedSubId = updatedSubId.filter(o => o !== `${value.subscriberId}|${value.stbNumber}`);
      }
      setSelectedStbId(updatedStbId)
      setSelectedSubId(updatedSubId)
    }

  }

  const updatedBoxName = (updatedData) => {
    setBoxlistData(updatedData)
  }
  const handleOperation = (operation) => {
    if (operation === 'Resume') {
      handleBulkResume(operation)
    } else {
      setActiveStep(2)
      setOperation(operation)
      setSession('sbl', { selectedStb: selectedStbId, selectedSub: selectedSubId })
    }
  }
  const handleBulkResume = async (operation) => {
    setPageLoading(true)
    const payload = {
      "subIdStb": selectedSubId.map(item => item),
      "resDate": getDateFormatYYYYMMDD(new Date()),
      "stbType": boxlistData.boxlist.find(f => f.stbNumber === selectedStbId[0]).connectionType,
      "actionType": operation,
      "activityType": `Bulk ${operation}`,
      "susDate": ""
    };

    try {
      const response = await commonService.bulkSuspend(payload);
      const responseData = response.data;
      setSession('bulkOpetaionResponse', responseData)
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        // const data = responseData.data;
        setPageLoading(false)
        history.push('/operation-success')
      }
    } catch {
      //   console.error(MESSAGES.DID_MOUNT_ERROR);
      setPageLoading(false)
    }
  }
  const reselectedSubId = () => {
    if (!isBack) {
      setSelectedStbId([])
      setSelectedSubId([])
    } else {
      setIsBack(false)
    }
  }
  const handleBack = () => {
    setIsBack(true)
    setActiveStep(1)
    const selectedBoxList = getSession('sbl')
    setSelectedStbId(selectedBoxList.selectedStb)
    setSelectedSubId(selectedBoxList.selectedSub)
  }
  if (pageLoading)
  return <Loader />
  return (
    <div className="b2b-bulk-status-update">
      <form method="post" ref={getThisForm} className="d-none" target='_blank'>
          <textarea ref={recomendationParamInput} name="recomendationParam" />
        </form>
      {<Stepper stepOne="Step 1: Select the Box" stepTwo="Step 2: Select the date" activeStep={activeStep} />}
      {activeStep === 1 &&
        <TableWithAction
          selectedStbId={selectedStbId}
          handleSelectSubId={handleSelectSubId}
          boxlistData={boxlistData}
          updatedBoxName={updatedBoxName}
          loading={loading}
          allRadio={false}
          reselectedSubId={reselectedSubId}
          recomendationParamInput={recomendationParamInput}
          getThisForm={getThisForm}
        />}
      {activeStep === 2 &&
        <SelectDates
          operation={operation}
          boxlistData={boxlistData}
          selectedSubId={selectedSubId}
          selectedStbId={selectedStbId}
          handleBack={handleBack}
        />}
      {activeStep === 1 && <div className='bulkOperationFooter'>
        <div className='footerWrapper'>
          {showMore && activeStep === 1 && <div className='bulkOperationFooter-loadmore-sec'>
            {/* {selectedStbId.length > 0 && <span className='in-footer box-text small'>Selected Boxes ({selectedStbId.length})</span>} */}
            <CustomButton
              content={`Load More Boxes (${boxlistData && boxlistData.boxlist.length})`}
              classes="primary"
              config={{ type: 'button', class: 'secondary' }}
              onClick={getBoxIdList}
            />
          </div>}
          <div className='bulkOperationFooter-button-sec'>
            {activeStep === 1 && <>
              <CustomButton
                content="Suspend"
                classes="primary"
                config={{ type: 'button' }}
                onClick={() => handleOperation('Suspend')}
                disabled={selectedStbId.length < 2}
              />
              <CustomButton
                content="Resume"
                classes="primary"
                config={{ type: 'button' }}
                onClick={() => handleOperation('Resume')}
                disabled={selectedStbId.length < 2}
              />
            </>
            }
          </div>
        </div>
      </div>}
      <Modal />
      <ToastInfo />
    </div>
  )
}

export default BulkStatusUpdate;
