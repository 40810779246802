import React, { useEffect, useState } from 'react';
import CustomTable from '../Common/CustomTable';
import _debounce from 'lodash/debounce';
import TableColumns from './TableColumns';
import _isEmpty from 'lodash/isEmpty';
import SearchBar from '../Common/SearchBar';
import { commonService } from '../../storeManager/common/services'
import GlobalConfig from '../../globalConfig/globalConfig'
import ContentLoader from '../Common/ContentLoader';
import { getDateFormatYYYYMMDD } from "../../helper";
import './style.scss'
import Loader from '../Loader';
import { getSession, setSession, sortBoxData } from '../../services/commonUtils';
import { MODALS } from "../../storeManager/modal/constants";
import Modal from "../Common/Modal";
import { openModal as openModalHandler } from "../../storeManager/modal/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const TableWithAction = (props) => {

    const { selectedStbId, handleSelectSubId, getFilter, boxlistData, updatedBoxName, loading, allRadio = true, reselectedSubId, recomendationParamInput, getThisForm } = props;

    const [searchedList, setSearchedList] = useState([]);
    const [connectionType, setConnectionType] = useState(allRadio ? 'All' : 'Primary');
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(sortBoxData(boxlistData.boxlist));
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setSearchInput('')
        if (allRadio) {
            setFilteredData(sortBoxData(boxlistData.boxlist))
            setConnectionType('All')
        } else {
            const selectedConnectionType = getSession('ct') || 'Primary'
            setConnectionType(selectedConnectionType)
            handleSearchFilter(selectedConnectionType)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boxlistData]);

    const BoxNameUpdateInTable = ({ vcNumber, newBoxName, subscriberId }) => {
        const updatedBoxListData = boxlistData;
        updatedBoxListData.boxlist.forEach(el => {
            if (el.vcNumber === vcNumber)
                el.boxAlias = newBoxName;
        })
        updatedBoxName(updatedBoxListData)
    }

    const rechargeRedirect = (subId) => async () => {

        try {
            const response = await commonService.redirectRechargeApi(subId);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                // this.setState({ isLoading: false });
                const rechargeUrl = responseData.data;
                window.open(rechargeUrl, '_blank')
            } else {
                // this.setState({ error: responseData.message, isLoading: false })
            }
        } catch {
            // this.setState({ error: GlobalConfig.serverError, isLoading: false })
        }
    };

    const redirectModifyPack = (subId, name) => async () => {
        const payload = {
            "subscriberId": subId,
            "subscriberName": name
        };
        try {
            const response = await commonService.redirectModifyPackApi(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                const modifyPackData = responseData.data;
                const recommendationParamElem = recomendationParamInput.current;
                recommendationParamElem.value = modifyPackData.recomendationParam;
                const getThisForm1 = getThisForm.current;
                getThisForm1.action = modifyPackData.redirectUrl;
                getThisForm1.submit();

            } else {
                // this.setState({ error: responseData.message, isLoading: false })
            }
        } catch {
            // this.setState({ error: GlobalConfig.serverError, isLoading: false })
            console.log("error")
        }
    };
    const handleSearchFilter = (value) => {
        let finalResult = [];
        reselectedSubId()
        setConnectionType(value)
        if (!allRadio)
            setSession('ct', value)
        getFilter && getFilter(value);
        if (value !== 'All')
            finalResult = [...sortBoxData(boxlistData.boxlist).filter(el => el.connectionType.toLowerCase().trim() === value.toLowerCase().trim())]
        else
            finalResult = [...sortBoxData(boxlistData.boxlist)]
        if (searchInput.length > 2) {
            const searchResult = doSearch(searchInput, finalResult, true)
            setSearchedList(searchResult)
        }
        setFilteredData(finalResult)

    }

    const doSearch = (input, data = [], filterFlag = false) => {
        const rawData = filterFlag ? data : filteredData;
        let resultData = [...rawData.filter(e =>
            e.boxAlias.toLowerCase().trim().includes(input)
            || e.subscriberId.toLowerCase().trim().includes(input)
            || e.vcNumber.toLowerCase().trim().includes(input))]
        return resultData;
    }
    const handleSearch = _debounce((input) => {
        setSearchInput(input)
        if (input.length > 2 && !_isEmpty(input)) {
            const resultData = doSearch(input.toLowerCase().trim());
            setSearchedList(resultData)
        }
        else {
            boxlistData && setSearchedList([])
            // setSearchInput('')
        }
    }, 500);

    const handleResume = async (row, rowIndex) => {
        setIsLoading(true)
        const payload = {
            "actionType": "Resume",
            "resumeDate": getDateFormatYYYYMMDD(new Date()),
            "stbNumber": row.stbNumber,
            "stbType": row.connectionType,
            "subscriberId": row.subscriberId,
            "suspDate": "",
            rowIndex
        };

        try {
            const { openModal } = props;
            const response = await commonService.suspendResumeStatusApi(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                setIsLoading(false)
                openModal(MODALS.CONFIRMATION_POPUP, { message: responseData.message, hideCloseButton: true, reDirectLinkHide: true, showOkCloseButton: true })
            } else {
                setIsLoading(false)
                openModal(MODALS.CONFIRMATION_POPUP, { message: responseData.message, reDirectLinkHide: true, showOkCloseButton: true })
                // this.setState({ error: responseData.message, isLoading: false })
            }
        } catch {
            setIsLoading(true)
        }
    }; 
    const updateHeavyRefresh = (subscriberId) => {
        const updatedBoxListData = boxlistData;
        updatedBoxListData.boxlist.forEach(el => {
            if (el.subscriberId === subscriberId)
                el.hardReset = false;
        })
        updatedBoxName(updatedBoxListData)
    }
    if ((loading && boxlistData && boxlistData.boxlist.length <= 0) || isLoading)
        return (
            <Loader />
        )
    return (
        <div className='table-with-action'>
            <h2 className='box-text'>Selected Boxes ({selectedStbId.length} / {!loading && boxlistData && searchInput.length > 0 ? searchedList.length : filteredData.length})</h2>
            <fieldset disabled={loading || (boxlistData && boxlistData.boxlist.length <= 0)} className='search-filter-wrap d-flex justify-content-between'>
                <SearchBar placeholder="Subscriber ID, Digicard/CDSN, Box Friendly Name" onChange={handleSearch} value={searchInput} />
                <div className='select-type-radio search-content'>
                    {allRadio && <label htmlFor='All'><input type="radio" name='Search-Type' value="All" id="All" onChange={e => handleSearchFilter(e.target.value)} checked={'All' === connectionType} />All</label>}
                    <label htmlFor='SubIdLevel'><input type="radio" name='Search-Type' value="Primary" id="SubIdLevel" onChange={e => handleSearchFilter(e.target.value)} checked={'Primary' === connectionType} />Perform Action on Sub ID Level</label>
                    <label htmlFor='BoxLevel'><input type="radio" name='Search-Type' value="Secondary" id="BoxLevel" onChange={e => handleSearchFilter(e.target.value)} checked={'Secondary' === connectionType} />Perform Action on Secondary Box</label>
                </div>
            </fieldset>
            {(!boxlistData || loading) && <div className="search-wrapper">
                <ContentLoader rows={1} area='content-loader-container' />
            </div>}
            <CustomTable
                columns={TableColumns({ BoxNameUpdateInTable, rechargeRedirect, redirectModifyPack, handleResume, updateHeavyRefresh })}
                handleChange={handleSelectSubId}
                data={searchInput.length > 2 ? searchedList : filteredData}
                checkbox={true}
                selectAll={true}
                selectedStbId={selectedStbId}
                valueKey="stbNumber"
            />
            <Modal />
            {/*selectedStbId.length > 0 && <h2 className='box-text small'>Selected Boxes({selectedStbId.length})</h2>*/}
        </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};
export default connect(null, mapDispatchToProps)(TableWithAction);