import React, { useState } from 'react'
import PackIndependentAccordion from '../../Common/PackIndependentAccordion';
// import AccDummyData from '../../../mocks/AccDummyData.json'
import { isArray, isEmpty } from 'lodash';
import CustomButton from '../../Common/Button';

const BroadcasterPack = (props) => {
    const { data, onChange, selectedPack, handleNext, isDashboard, handleProceed } = props;
    const [openId, setOpenId] = useState(0)
    const toggleAccordian = (id) => {
        if (openId === id) {
            setOpenId(-1);
        } else {
            setOpenId(id);
            window.scrollTo({ top: 100 + (50*id), behavior: 'smooth' })
        }
    };
    return (
        <div className='Broadcaster'>
            <div className='accordion'>
                {
                    isArray(data) && data.map((el, index) => isArray(el.packages) && el.packages.length > 0 ? (
                        <PackIndependentAccordion
                            accordionData={el}
                            isOpen={index === openId}
                            key={`${el.name}-${index}`}
                            checkTest={index}
                            toggleAccordian={() => toggleAccordian(index)}
                            onChange={onChange}
                            selectedPack={selectedPack}
                        />
                    )
                        :
                        null
                    )
                }
            </div>
            <div className='bulkOperationFooter'>
                <div className='footerWrapper'>
                    {isDashboard ?
                        <CustomButton
                            content="Proceed"
                            classes="primary"
                            config={{ type: 'button' }}
                            onClick={handleProceed}
                            disabled={isEmpty(selectedPack)}
                        />
                        :
                        <CustomButton
                            content="Next"
                            classes="primary"
                            config={{ type: 'button' }}
                            onClick={handleNext}
                            disabled={isEmpty(selectedPack)}
                        />}
                </div>
            </div>
        </div>
    )
}

export default BroadcasterPack;