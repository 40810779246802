import React, { useEffect, useState } from 'react';
import { isArray } from 'lodash';

const CustomTable = (props) => {
    const { handleChange, data, columns, checkbox = false, selectedStbId = [], valueKey } = props;

    const [colList, setColList] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    // const [colKeyList, setColKeyList] = useState([]);
    // const [isSelectAll, setIsSelectAll] = useState(false);


    useEffect(() => {
        const title = [];
        const key = [];
        if(selectedStbId.length <= 0){
            setIsChecked(false)
        }
        isArray(columns) && columns.forEach(element => {
            key.push(element.key);
            title.push(element.text);
        });
        setColList(title);
        // setColKeyList(key);

    }, [data, columns, selectedStbId]);

    const showColumns = () => {
        const selectAll = (<td>
            <div className='select-all-wrap'>Select All </div>
            <label >
                <input id='SelectAllCheckbox' type="checkbox" name="" onChange={e => handleSelectAll(e)} checked={ isChecked && selectedStbId.length > 0 }/>
            </label>
        </td>)
        const columns = colList.map((item, index) => (<td className={`column-${index+1}`} dangerouslySetInnerHTML={{ __html: item }} key={`${item}-${index}`}></td>))
        checkbox && selectAll ? columns.splice(0, 0, selectAll) : columns.splice(0, 0, '')
        return columns;
    }

    const getRowData = (row, rowIndex) => {

        const rowData = columns.map((item, index) => {
            if (item.formatter)
                return (<td key={`${row[item.key]}-${index}`}> {item.formatter(row[item.key] || '-', row, rowIndex)} </td>)
            else
                return (
                    <td key={`${row[item.key]}-${index}`}>{row[item.key] || '-'}</td>
                )
        })
        checkbox &&
            rowData.splice(0, 0, (<td>
                <label >
                    <input
                        type="checkbox"
                        id={row.subId}
                        name='userList'
                        checked={selectedStbId.includes(row[valueKey]) ? true : false}
                        onChange={(e) => handleChange(e, row, false)} />
                    {/*<span className="che ckmark"></span>*/}
                </label>
            </td>))
        return rowData;
    }
    const fetchTableData = () => {
        const dataTable = isArray(data) && data.map((item, ind) => {
            return (
                <tr key={`key-${ind}`}>
                    {
                        getRowData(item, ind)
                    }
                </tr>
            )

        })
        return dataTable;
    }
    const handleSelectAll = (e) => {
        setIsChecked(!isChecked)
        if (e.target.checked)
            handleChange(e, data, true)
        else
            handleChange(e, [], true)

    }
    // const handleSelect = (e, value) => {
    //     handleChange(e, value)
    // }

    return (<>
        <table className='subscriber-details-table'>
            <thead>
                <tr>
                    {showColumns()}
                </tr>
            </thead>
            <tbody>
                {fetchTableData()}
            </tbody>
        </table>
        {data.length <= 0 && <div className="no-data-found mt30">No data found</div>}
    </>)
}

export default CustomTable;